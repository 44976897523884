import React, { useState, useEffect } from 'react';
import SideMedicalDepartment from './side_MedicalDepartment';
import { useNavigate } from 'react-router-dom';
import '../css/side_drProfile.css';

const SideDrProfile = ({ drInfo, onClose, isOpen, onOpenMedicalDepartment }) => {
  const navigate = useNavigate();

  const openMedicalDept = () => {
    onOpenMedicalDepartment()
  };

  const goDoctorDetail = () => {
    navigate('/EditProfile');
  };

  const handleClose = (e) => {
    console.log('Close button clicked'); // 추가된 로그
    e.preventDefault(); // 이벤트 기본 동작 방지
    e.stopPropagation();
    onClose();
  };

  const formatBirthDate = () => {
    const year = drInfo.dr_year || '';
    const month = drInfo.dr_month ? String(drInfo.dr_month).padStart(2, '0') : '';
    const day = drInfo.dr_day ? String(drInfo.dr_day).padStart(2, '0') : '';
    return `${month}/${day}/${year}`;
  };


  if (!isOpen) return null;
  return (
    <div className={`sdp-div ${isOpen ? 'open' : ''}`}>
      <div className="sdp-div-2">
         <img
             loading="lazy"
             src="./icons/web_icon/img_x35.png"
             className="sdp-img-2"
             onClick={handleClose}
              alt="Close"
             />
        <img
          loading="lazy"
          src="./icons/web_icon/img_sdp_Setting.png"
          className="sdp-img"
          onClick={goDoctorDetail}
          alt="Menu"
        />
      </div>
      <div className="sdp-InfoContainer">
        <div className="sdp-div-3">
          <img
            loading="lazy"
            src={drInfo.profileImage}
            className="sdp-img-3"
            alt={drInfo.name}
          />
          <div className="sdp-james-adams">{drInfo.name}</div>
        </div>
        <div className="sdp-info-row">
          <div className="sdp-info-label">Date of Birth</div>
          <div className="sdp-info-value">{formatBirthDate()}</div>
        </div>
        <div className="sdp-info-row">
          <div className="sdp-info-label">Gender</div>
          <div className="sdp-info-value">{drInfo.gender}</div>
        </div>
        <div className="sdp-info-row">
          <div className="sdp-info-label">Phone Number</div>
          <div className="sdp-info-value">{drInfo.PhoneNumber}</div>
        </div>
        <div className="sdp-info-row">
          <div className="sdp-info-label">Affiliation</div>
          <div className="sdp-info-value">{drInfo.doctorType}</div>
        </div>
        <div className="sdp-info-row">
          <div className="sdp-info-label">Address</div>
          <div className="sdp-info-value">{drInfo.hospitalAddress}<br />{drInfo.hospitalAddress2}</div>
        </div>
        <div className="sdp-div-7" />
        <div className="sdp-medical-department">Medical department</div>
        <button className="sdp-div-8" onClick={openMedicalDept}>
        <img
          loading="lazy"
          src="./icons/web_icon/img_iconMedicalDepartment.png"
          className="sdp-img-4"
          alt="Medical department icon"
        />
        <div>Medical department</div>
      </button>
        <div className="sdp-info-section">
          <div className="sdp-info-title">Experience</div>
          <div className="sdp-info-content">{drInfo.experience}</div>
        </div>
        <div className="sdp-div-9" />
        <div className="sdp-info-section">
          <div className="sdp-info-title">Self-introduction</div>
          <div className="sdp-info-content">{drInfo.greeting}</div>
        </div>
        <div className="sdp-info-section">
          <div className="sdp-info-title">Closing Remarks</div>
          <div className="sdp-info-content">{drInfo.closingRemarks}</div>
        </div>
      </div>
    </div>
  );
};

export default SideDrProfile;
