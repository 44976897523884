import React from 'react';
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';
import logoImage from './assets/logo.jpg';
import MediNoxImage from './assets/MediKnocks.jpg';

Font.register({
  family: 'Signatie',
  src: '/fonts/Signatie.otf'
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    padding: 30,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
  },
  tableCol: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
    fontFamily: 'Helvetica',
  },
  boldText: {
    fontWeight: 'bold'
  },
  footer: {
    fontSize: 25,
    textAlign: 'center',
    color: 'grey',
    fontFamily: 'Helvetica',
    marginTop: 10,
  },
  signature: {
    fontFamily: 'Signatie',
    fontSize: 20,
    textAlign: 'left',
    paddingLeft: 5,
  },
  rxSymbol: {
    width: 30,
    height: 30,
    alignSelf: 'center',
  },
  MediNoxSymbol: {
    width: 200,
    height: 'auto',
    alignSelf: 'center',
    marginTop: '5px',
    marginBottom: '5px'
  },
  fullWidth: {
    width: '100%',
  },
  centerText: {
    textAlign: 'center',
  },
});

const calculateBirthDate = (year, month, day) => {
  return `${day.toString().padStart(2, '0')}-${month.toString().padStart(2, '0')}-${year}`;
};

const DrugInfo = ({ drug, index }) => (
  <View>
    <View style={styles.tableRow}>
      <View style={[styles.tableCol, { width: "100%" }]}>
        <Text style={[styles.tableCell, styles.boldText, styles.centerText]}>Drug #{index + 1}</Text>
      </View>
    </View>
    <View style={styles.tableRow}>
      <View style={[styles.tableCol, { width: "25%" }]}>
        <Text style={[styles.tableCell, styles.boldText]}>Drug name</Text>
      </View>
      <View style={[styles.tableCol, { width: "25%" }]}>
        <Text style={styles.tableCell}>{drug.drugName || ""}</Text>
      </View>
      <View style={[styles.tableCol, { width: "25%" }]}>
        <Text style={[styles.tableCell, styles.boldText]}>Drug Strength</Text>
      </View>
      <View style={[styles.tableCol, { width: "25%" }]}>
        <Text style={styles.tableCell}>{drug.drugStrength || ""}</Text>
      </View>
    </View>
    <View style={styles.tableRow}>
      <View style={[styles.tableCol, { width: "25%" }]}>
        <Text style={[styles.tableCell, styles.boldText]}>Dispense</Text>
      </View>
      <View style={[styles.tableCol, { width: "25%" }]}>
        <Text style={styles.tableCell}>{drug.dispense || ""}</Text>
      </View>
      <View style={[styles.tableCol, { width: "25%" }]}>
        <Text style={[styles.tableCell, styles.boldText]}>Dosage form</Text>
      </View>
      <View style={[styles.tableCol, { width: "25%" }]}>
        <Text style={styles.tableCell}>{drug.dosageForm || ""}</Text>
      </View>
    </View>
    <View style={styles.tableRow}>
      <View style={[styles.tableCol, { width: "25%" }]}>
        <Text style={[styles.tableCell, styles.boldText]}>Directions for use</Text>
      </View>
      <View style={[styles.tableCol, { width: "75%" }]}>
        <Text style={styles.tableCell}>{drug.directions || ""}</Text>
      </View>
    </View>
    <View style={styles.tableRow}>
      <View style={[styles.tableCol, { width: "25%" }]}>
        <Text style={[styles.tableCell, styles.boldText]}>Refills</Text>
      </View>
      <View style={[styles.tableCol, { width: "75%" }]}>
        <Text style={styles.tableCell}>{drug.refills || "0"}</Text>
      </View>
    </View>
    <View style={styles.tableRow}>
      <View style={[styles.tableCol, { width: "25%" }]}>
        <Text style={[styles.tableCell, styles.boldText]}>Doctor's Note</Text>
      </View>
      <View style={[styles.tableCol, { width: "75%" }]}>
        <Text style={styles.tableCell}>{drug.doctorsNote || ""}</Text>
      </View>
    </View>
  </View>
);

const PrescriptionDocument = ({ data, userInfo, drInfo }) => {
  console.log('PrescriptionDocument userInfo:', userInfo);
  console.log('PrescriptionDocument drInfo:', drInfo);

  // Safely access nested properties
  const birthDate = userInfo && userInfo.user_year && userInfo.user_month && userInfo.user_day
    ? calculateBirthDate(userInfo.user_year, userInfo.user_month, userInfo.user_day)
    : '';
  const doctorFullName = drInfo ? `Dr. ${drInfo.dr_firstname || ''} ${drInfo.dr_lastname || ''}` : 'Dr. ';
  const doctorAddress = drInfo
    ? `${drInfo.dr_address || ''}, ${drInfo.dr_address2 || ''}, ${drInfo.dr_city || ''}, ${drInfo.dr_state || ''} ${drInfo.dr_zipcode || ''}`
    : 'Doctor\'s address';
  const doctorType = drInfo.dr_doctorType;

  const patientName = userInfo ? `${userInfo.user_firstname || ''} ${userInfo.user_lastname || ''}` : '';
  const patientAddress = userInfo
    ? `${userInfo.user_address || ''}, ${userInfo.user_city || ''}, ${userInfo.user_state || ''} ${userInfo.user_zipcode || ''}`
    : '';
    
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.fullWidth]}>
              <Text style={[styles.tableCell, styles.boldText, styles.centerText]}>{doctorFullName} &#40; DEA: FK3533418 &#41;</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.fullWidth]}>
              <Text style={[styles.tableCell, styles.centerText]}>
                {doctorType === 'unaffiliated' ? '5708 Sean Paul Lane Georgetown Texas (78628)' : doctorAddress}
                </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, { width: "15%" }]}>
              <Text style={[styles.tableCell, styles.boldText]}>Name</Text>
            </View>
            <View style={[styles.tableCol, { width: "35%" }]}>
              <Text style={styles.tableCell}>{patientName}</Text>
            </View>
            <View style={[styles.tableCol, { width: "15%" }]}>
              <Text style={[styles.tableCell, styles.boldText]}>Date of birth</Text>
            </View>
            <View style={[styles.tableCol, { width: "35%" }]}>
              <Text style={styles.tableCell}>{birthDate}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, { width: "15%" }]}>
              <Text style={[styles.tableCell, styles.boldText]}>Address</Text>
            </View>
            <View style={[styles.tableCol, { width: "85%" }]}>
              <Text style={styles.tableCell}>{patientAddress}</Text>
            </View>
          </View>
        
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, { width: "15.9%", justifyContent: 'center', alignItems: 'center' }]}>
              <Image style={styles.rxSymbol} src={logoImage} />
            </View>
            <View style={[styles.tableCol, { width: "90%" }]}>
              {data.drugs.map((drug, index) => (
                <DrugInfo key={index} drug={drug} index={index} />
              ))}
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, { width: "17.5%" }]}>
              <Text style={[styles.tableCell, styles.boldText]}>Signature</Text>
            </View>
            <View style={[styles.tableCol, { width: "49%" }]}>
              <Text style={styles.signature}>{drInfo ? `${drInfo.dr_firstname} ${drInfo.dr_lastname}` : ''}</Text>
            </View>
            <View style={[styles.tableCol, { width: "25%" }]}>
              <Text style={[styles.tableCell, styles.boldText]}>Date</Text>
            </View>
            <View style={[styles.tableCol, { width: "25%" }]}>
              <Text style={styles.tableCell}>{new Date().toLocaleDateString()}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, { width: "15%" }]}>
              <Text style={[styles.tableCell, styles.boldText]}>Issued by</Text>
            </View>
            <View style={[styles.tableCol, { width: "85%" }]}>
             <Image style={styles.MediNoxSymbol} src={MediNoxImage} />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PrescriptionDocument;