import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/public.css';
import '../css/SignUp_Main.css';
import ProgressBars from './ProgressBars';
import { useLoadScript } from '@react-google-maps/api';
import Step1_CreateAccount from './Step1_CreateAccount';
import Step2_PersonalInfo from './Step2_PersonalInfo';
import Step3_HospitalInfo from './Step3_HospitalInfo';
import Step4_MedicalDepartment from './Step4_MedicalDepartment';
import Step5_LicenseAuthentication from './Step5_LicenseAuthentication';
import Step6_SelfIntroduction from './Step6_SelfIntroduction';
import Step7_Confirmation from './Step7_Confirmation';
import backIcon from './icons/back.png'; // 이미지 import
const IP = process.env.REACT_APP_HOST;
const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const libraries = ['places'];

function SignUp_Main() {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries,
    language: 'en',
  });

  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    dateOfBirth: null,
    gender: '',
    phoneNumber: '',
    doctorType: 'affiliated',
    hospitalAddress: '',
    zipCode: '',
    city: '',
    state: '',
    departments: [],
    hospitalAddressLine1: '',
    hospitalAddressLine2: '',
    licenseFile: null,
    experience: '',
    licenseInfo: '',
    contactInfo: '',
    profilePicture: null,
    selfIntroduction: '',
    closingRemarks: '',
    profilePicture: null,
  });

  const [errors, setErrors] = useState({});
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [emailMessage, setEmailMessage] = useState('');
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [touched, setTouched] = useState({});
  const [isLicenseUploaded, setIsLicenseUploaded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isEmailVerified) {
      setEmailMessage('Verified Your Email');
    }
  }, [isEmailVerified]);

  useEffect(() => {
    if (isLoaded) {
      // Google Maps API가 로드되면 필요한 초기화 작업을 수행할 수 있습니다.
    }
  }, [isLoaded]);

  const validateStep = (step) => {
    const newTouched = { ...touched };
    let stepErrors = {};

    switch (step) {
      case 1:
        newTouched.email = true;
        newTouched.password = true;
        newTouched.confirmPassword = true;
        if (!formData.email) stepErrors.email = "Email is required";
        if (!isEmailVerified) stepErrors.email = "";
        if (!formData.password) stepErrors.password = "Password is required";
        if (formData.password !== formData.confirmPassword) {
          stepErrors.confirmPassword = "Passwords do not match";
        }
        break;
      case 2:
        newTouched.firstName = true;
        newTouched.lastName = true;
        newTouched.dateOfBirth = true;
        newTouched.gender = true;
        newTouched.phoneNumber = true;
        newTouched.doctorType = true;
        if (!formData.firstName) stepErrors.firstName = "First name is required";
        if (!formData.lastName) stepErrors.lastName = "Last name is required";
        if (!formData.dateOfBirth) stepErrors.dateOfBirth = "Date of birth is required";
        if (!formData.gender) stepErrors.gender = "Gender is required";
        if (!formData.phoneNumber) stepErrors.phoneNumber = "Phone number is required";
        if (!formData.doctorType) stepErrors.doctorType = "Doctor type is required";
        break;
      case 3:
        newTouched.zipCode = true;
        newTouched.city = true;
        newTouched.state = true;
        newTouched.hospitalAddressLine1 = true;
        newTouched.experience = true;
        if (!formData.zipCode) stepErrors.zipCode = "ZIP Code is required";
        if (!formData.city) stepErrors.city = "City is required";
        if (!formData.state) stepErrors.state = "State is required";
        if (!formData.hospitalAddressLine1) stepErrors.hospitalAddressLine1 = "Address line 1 is required";
        if (!formData.experience) stepErrors.experience = "Experience is required";
        break;
      case 4:
        newTouched.departments = true;
        if (formData.departments.length === 0) stepErrors.departments = "At least one department must be selected";
        break;
      case 5:
        newTouched.licenseFile = true;
        if (!formData.licenseFile) stepErrors.licenseFile = "License file is required";
        break;
      case 6:
        newTouched.selfIntroduction = true;
        newTouched.closingRemarks = true;
        if (!formData.selfIntroduction.trim()) stepErrors.selfIntroduction = "Self-introduction is required";
        if (!formData.closingRemarks.trim()) stepErrors.closingRemarks = "Closing remarks are required";
        break;
    }

    setTouched(newTouched);
    setErrors(stepErrors);
    return Object.keys(stepErrors).length === 0;
  };

  const handleNext = (stepData) => {
    if (validateStep(currentStep)) {
      // 현재 폼 데이터와 새로운 단계 데이터를 병합
      setFormData(prevData => ({
        ...prevData,
        ...stepData
      }));

      if (currentStep === 6) {
        handleSubmit({...formData, ...stepData});
      } else {
        setCurrentStep(prevStep => prevStep + 1);
      }
    }
  };

  const handleBackArrow = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleSubmit = async (finalStepData) => {
    setSubmitAttempted(true);
    
    // Combine the final step data with the existing formData
    const completeFormData = { ...formData, ...finalStepData };

    try {
      const formDataToSend = new FormData();
  
      Object.keys(completeFormData).forEach(key => {
        if (key === 'departments') {
          formDataToSend.append(key, JSON.stringify(completeFormData[key]));
        } else if (completeFormData[key] instanceof File) {
          formDataToSend.append(key, completeFormData[key], completeFormData[key].name);
        } else if (completeFormData[key] !== null && completeFormData[key] !== undefined) {
          formDataToSend.append(key, completeFormData[key]);
        }
      });
  
      // zipCode를 zipcode로 변경
      formDataToSend.set('zipcode', formDataToSend.get('zipCode'));
      formDataToSend.delete('zipCode');
  
      const response = await axios.post(`/DrSignUp`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      window.localStorage.removeItem('emailForSignIn');
  
      console.log('Sign up submitted:', response.data);
      setCurrentStep(7);
    } catch (error) {
      console.error('Error submitting form:', error);
      if (error.response) {
        setErrors({ general: error.response.data.message });
      } else {
        setErrors({ general: "An unexpected error occurred" });
      }
    }
  };

  const renderStep = () => {
    const commonProps = {
      formData,
      setFormData,
      errors,
      setErrors,
      touched,
      setTouched,
      onBackClick: handleBackArrow,
      onNext: handleNext
    };

    switch (currentStep) {
      case 1:
        return <Step1_CreateAccount
          {...commonProps}
          isEmailVerified={isEmailVerified}
          setIsEmailVerified={setIsEmailVerified}
          emailMessage={emailMessage}     
          setEmailMessage={setEmailMessage}  
        />;
      case 2:
        return <Step2_PersonalInfo {...commonProps} />;
        case 3:
          return <Step3_HospitalInfo {...commonProps} isLoaded={isLoaded} loadError={loadError} />;
      case 4:
        return <Step4_MedicalDepartment {...commonProps} />;
      case 5:
        return <Step5_LicenseAuthentication 
          {...commonProps} 
          isLicenseUploaded={isLicenseUploaded}
          setIsLicenseUploaded={setIsLicenseUploaded}
        />;
      case 6:
        return <Step6_SelfIntroduction {...commonProps} />;
      case 7:
        return <Step7_Confirmation navigate={navigate} />;
      default:
        return null;
    }
  };

  return (
    <div className="footer_Style_0">
      <div className="fs0_topNaviBar">
        <div className="fs0_logo_container">
          <img src="/icons/web_icon/Logo.png" alt="MediKnocks Logo" className="logo-image" />
        </div>
        <div className="nav-menu">
          <button className="login-button" onClick={() => navigate('/Login')}>Login</button>
        </div>
      </div>
      <div className="content-container">
        <div className={`signup-form-container step-${currentStep}`}>
          <div className="header-container">
            {currentStep < 7 && (
              <ProgressBars 
                currentStep={currentStep} 
                totalSteps={6} 
                onBackClick={handleBackArrow}
                backIcon={backIcon}
              />
            )}
          </div>
          <form onSubmit={handleSubmit}>
            {renderStep()}
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignUp_Main;