import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/AboutUs.css';
import '../css/public.css';

function AboutUs() {
    const [menuVisible, setMenuVisible] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

      // 메뉴 표시/숨기기 토글 함수
      const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
      };

    return (
        <div className="aboutus-page-footer_Style_overflow">
            <div className="aboutus-page-fs0_topNaviBar">
                <div className="aboutus-page-fs0_logo_container">
                    <a href="/ES"><img src="/icons/web_icon/Logo.png" alt="MediKnocks Logo" className="aboutus-page-logo-image" /></a>
                    <div class="aboutus-page-nav-wrapper">
                        <div class="aboutus-page-sl-nav">
                            <img
                                src="/icons/web_icon/language.png"
                                alt="language icon"
                                className="aboutus-page-language-icon"
                            />
                            <ul>
                                <li onClick={toggleDropdown} className="aboutus-page-dropdown-trigger">
                                Español <i className="fa fa-angle-down" aria-hidden="true"></i>
                                <div className="aboutus-page-triangle"></div>
                                {isDropdownOpen && (
                                    <ul className="aboutus-page-dropdown-menu">
                                        <a href = "/AboutUs"><li><span>English</span></li></a>
                                        <li>
                                            <span className="aboutus-page-active">Español</span>
                                        </li>
                                    </ul>
                                )}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="aboutus-page-nav-wrapper-400">
                        <div className="aboutus-page-sl-nav">
                            <img onClick={toggleDropdown}
                                src="/icons/web_icon/language.png"
                                alt="language icon"
                                className="aboutus-page-language-icon"
                            />
                            <ul>
                                <li onClick={toggleDropdown} className="aboutus-page-dropdown-trigger">
                                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                                    <div className="aboutus-page-triangle-400"></div>
                                    {isDropdownOpen && (
                                        <ul className="aboutus-page-dropdown-menu-400">
                                        <a href="/AboutUs"><li><span>English</span></li></a>
                                        <li>
                                            <span className="aboutus-page-active">Español</span>
                                        </li>
                                        </ul>
                                    )}
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/**1000px 이하에서만 보임 */}
                    <img 
                        src="/icons/web_icon/menu_45px.png" 
                        alt="menu Icon Image" 
                        className="aboutus-page-menu-image"
                        onClick={toggleMenu}
                    />
                </div>

                {/* 메뉴 컨테이너 */}
                <div className={`fs0_MenuContainerMobile ${menuVisible ? 'visible' : ''}`}>
                <button className="start-page-close-btn" onClick={toggleMenu}>X</button>
                    {/* 메뉴 내용 */}
                    <ul className="start-page-menu-list">
                    <li><a href="/AboutUs" className="start-page-menu-list-fs0_text">Sobre Nosotros</a></li>
                    <li><a href="/HowHealthy" className="start-page-menu-list-fs0_text">Evento</a></li>
                    </ul>
                </div>

                <nav className="fs0_MenuContainer">
                    <a href="/AboutUs" className="fs0_text">Sobre Nosotros</a>
                    <a href="/HowHealthy" className="fs0_text">Evento</a>
                    {/* <a href="#user-app" className="fs0_text">User App</a>
                    <a href="#doctor-guide" className="fs0_text">Doctor Guide</a> */}
                </nav>
            </div>
            <main className="aboutus-page-main">
                <section id="aboutus-page-main-here" className="hero">
                <div className="hero-content">
                    <p className="aboutus-page-hero-content-p1">Sobre nosotros</p>
                    <h2 className="aboutus-page-hero-h2">Todos deberían tener <br/> acceso a atención médica <br/> de alta calidad</h2>
                    <p>El nuevo servicio de telemedicina, <span className='mediknocks'>MediKnocks</span></p>
                </div>
                </section>
                <section id="section05" class="demo">
                        <a href="#aboutus-page-what-is">
                            <span></span>Scroll
                        </a>
                </section>
            </main>
            <main className="aboutus-page-main-content">
                <section id = "aboutus-page-what-is" className="aboutus-page-what-is">
                    <p className="aboutus-page-what-is-content">
                        ¡Bienvenido a Mediknocks! <br/><br/>
                        En Mediknocks, <span className='mediknocks'>creemos que todos merecen tener acceso a atención médica de alta calidad a un precio accesible,<br/>
                        sin importar su estado de seguro.</span> Nuestra innovadora plataforma de telemedicina te conecta con profesionales de la salud certificados<br/>
                        a través de consultas por texto, asegurando que recibas atención experta cuando y donde la necesites—con o sin la necesidad de videollamadas.<br/>
                    </p>
                    <div className="what-is-content1">
                        <img className= "aboutus-page-img-2" src="/icons/web_icon/img_start2.png" alt="Doctor" />
                    </div>
                </section>
                
                <section className="aboutus-page-what-is2">
                    <p className="aboutus-page-what-is-content">
                        Ya sea a través de chats de texto en tiempo real o en el momento que te convenga, <br/>
                        <span className='mediknocks'>Mediknocks te permite recibir la atención médica que necesitas sin las barreras habituales de tiempo, ubicación o altos costos.</span><br/>
                        Con nuestro sistema, las recetas se entregan rápidamente, para que puedas recibir el tratamiento que necesitas sin demora.<br/>
                    </p>
                    <div className="what-is-content">
                        <img class="aboutus-page-img-3" src="/icons/web_icon/DanielKim.jpg" alt="Doctor" />
                        <div className="what-is-text text_right">
                            <div className="aboutus_div_fontUP1">
                                Daniel Kim
                            </div>
                            <div className="aboutus_div_fontUP2">
                                Director Ejecutivo
                            </div>
                        </div>
                    </div>
                </section>

                <section className="aboutus-page-what-is">
                    <p className="aboutus-page-what-is-content">
                        Nuestra plataforma también admite la comunicación en varios idiomas. <br/>
                        <span className='mediknocks'>Gracias a nuestra traducción automática impulsada por IA,</span> puedes consultar con los médicos en el idioma con el que te sientas más cómodo, <br/>
                        sin preocupaciones por las barreras de comunicación.<br/>
                    </p>
                    <div className="what-is-content">
                        <img class="aboutus-page-img-3" src="/icons/web_icon/PhillipKim.jpg" alt="Doctor" />
                        <div className="what-is-text text_right">
                            <div className="aboutus_div_fontUP1">
                                Phillip Kim
                            </div>
                            <div className="aboutus_div_fontUP2">
                                Director de Operaciones
                            </div>
                        </div>
                    </div>
                </section>

                <section className="aboutus-page-what-is4">
                    <p className="aboutus-page-what-is-content">
                        Nuestra misión es hacer que la atención médica sea accesible, asequible y eficiente para todos. <br/>
                        <span className='mediknocks'>¿No tienes seguro? No hay problema. </span><br/>
                        Con Mediknocks, la atención de calidad está disponible para todos, en cualquier momento y en cualquier lugar.<br/><br/>

                        Experimenta atención médica asequible y de calidad con Mediknocks—bajo tus propios términos, <br/>
                        a través de consultas simples y eficientes basadas en texto.<br/>

                        Gracias. <br/>
                    </p>

                    <img class="aboutus-page-img-4" src="/icons/web_icon/MediKnocksCEO.jpg" alt="Doctor" />
                </section>
            </main>

            <footer className="footer">
                <div className="footer-content">
                    <div className="footer-section">
                    <img src="/icons/web_icon/Logo.png" alt="MediKnocks Logo" className="footer-logo-image" />
                        <h3>Headquarters</h3>
                        <p>
                            MediKnocks, Inc. <br/>
                            5708 Sean Paul Lane <br/>
                            Georgetown Texas (78628) <br/><br/>
                            ⓒ 2022 MediKnocks, Inc.
                        </p>
                    </div>
                    <div className="footer-section">
                        <h3 className="footer-section-title">Sobre nosotros</h3>
                        <a href="/AboutUs/ES"><p>Sobre nosotros</p></a>
                        <a href= "/HowHealthy"><p>Evento</p></a>
                    </div>
                    <div className="footer-section">
                        <h3>Soporte al cliente y comentarios</h3>
                        <p>Correo electrónico: <a href="mailto:support@mediknocks.com" style={{color:"#01b6cd"}}>support@mediknocks.com</a></p>
                    </div>

                    <a href ="https://www.instagram.com/mediknocks/?fbclid=IwZXh0bgNhZW0CMTEAAR0IPClgflIEwm9gMrGz5EaOxbgdDMIFb2-TDzG8fOrX3qwRJ1cMXQXXrY4_aem_z93n3KVVwYD01rMLdd2dzQ"><img src="/icons/web_icon/Instagram_Glyph_Gradient.png" alt="instagram" className="start-page-sns-button1"/></a>
                    <a href = "https://www.facebook.com/profile.php?id=61567539849561"><img src="/icons/web_icon/Facebook_Logo_Primary.png" alt="facebook" className="start-page-sns-button1"/></a>
                </div>
            </footer>
        </div>
    );
}

export default AboutUs;