import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/SignUp_Main.css';
import '../css/Step7_Confirmation.css';
import logoImage from './icons/Logo.png';

function Step7_Confirmation() {
  const navigate = useNavigate();

  const handleNextClick = () => {
    navigate('/Login');
  };

  return (
    <div className="step7-container">
      <img src={logoImage} alt="MediKnocks Logo" className="logo2" />
      <h2 className="confirmation-title">Thank you for signing up</h2>
      <p className="confirmation-message">
        We will notify you via email once your registration is approved.
      </p>
      <div className="step7_button-container">
        <button onClick={handleNextClick} className="next-button">
          OK
        </button>
      </div>
    </div>
  );
}

export default Step7_Confirmation;