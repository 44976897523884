import React from 'react';
import '../css/ExpiredCallPopup.css';

const ExpiredCallPopup = ({ onClose }) => (
  <div className="expired-call-popup">
    <div className="popup-content">
      <h2>User Call Expired</h2>
      <p>This call has already been taken. Please select another call.</p>
      <button onClick={onClose}>OK</button>
    </div>
  </div>
);

export default ExpiredCallPopup;