import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/public.css';
import '../css/Login.css';

const IP = process.env.REACT_APP_HOST;

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [loginError, setLoginError] = useState('');
    const [isEmailFocused, setIsEmailFocused] = useState(false);
    const [isPasswordFocused, setIsPasswordFocused] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        fillForm();
    }, []);

    const validateEmail = (email) => {
        if (!email) {
            return { isValid: false, message: 'Please enter your email.' };
        }
        
        const re = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
        return {
            isValid: re.test(String(email).toLowerCase()),
            message: re.test(String(email).toLowerCase()) ? '' : 'Please check your email'
        };
    };

    const handleEmailFocus = () => {
        setIsEmailFocused(true);
        setEmailError(''); 
        setLoginError('');  
    };
    
    const handlePasswordFocus = () => {
        setIsPasswordFocused(true);
        setPasswordError('');  
        setLoginError(''); 
        setEmailError('');
    };

    const validatePassword = (password) => {
        if (!password) {
            return { isValid: false, message: 'Please enter your password.' };
        }

        const re = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()\-_=+\\|[\]{};:\'",.<>\/?]).{8,15}$/;
        return {
            isValid: re.test(password),
            message: re.test(password) ? '' : 'Please check your password'
        };
    };

    const validateForm = () => {
        const emailValidation = validateEmail(email);
        const passwordValidation = validatePassword(password);

        setEmailError(emailValidation.message);
        setPasswordError(passwordValidation.message);

        return emailValidation.isValid && passwordValidation.isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitted(true);
        const isValid = validateForm();

        if (isValid) {
            try {
                // First, check if the email exists
                const emailCheckResponse = await axios.post(`/drEmailCheck`, { email },{
                    withCredentials: true
                });
                console.log(emailCheckResponse.data.success);
                if (emailCheckResponse.data.success) {
                    // Email doesn't exist
                    setEmailError("User not found. Please check your email or sign up");
                    setLoginError('');
                } else {
                    // Email exists, proceed with login
                    const loginResponse = await axios.post(`/Login`, {
                        email,
                        password,
                        rememberMe
                    }, {
                        withCredentials: true
                    });
                    localStorage.setItem('email', email);
                    console.log(loginResponse);

                    if (loginResponse.status === 200) {
                        handleRememberMe();
                        navigate('/WebChat');
                    }
                }
            } catch (error) {
                console.error('Login error:', error);
                if (error.response) {
                    if (error.response.status === 401) {
                        setEmailError("User not found. Please check your email or sign up");
                        setPasswordError('');
                    } else if (error.response.status === 402) {
                        setEmailError("The doctor's license is not verified");
                        setPasswordError('');
                    } else if (error.response.status === 403) {
                        setEmailError("This account is already logged in from another device");
                        setPasswordError('');
                    } else {
                        setLoginError('An error occurred. Please try again later.');
                    }
                } else {
                    setLoginError('An error occurred. Please try again later.');
                }
            }
        } else {
            setLoginError('Please correct the errors and try again.');
        }
    };

    const handleClearEmail = () => {
        setEmail('');
        setEmailError('');
    };

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    const handleRememberMe = () => {
        if (rememberMe) {
            localStorage.setItem('email', email);
            localStorage.setItem('rememberMe', 'true');
        } else {
            // localStorage.removeItem('email');
            localStorage.removeItem('rememberMe');
        }
    };

    const fillForm = () => {
        const savedEmail = localStorage.getItem('email');
        const savedRememberMe = localStorage.getItem('rememberMe');

        if (savedEmail && savedRememberMe === 'true') {
            setEmail(savedEmail);
            setRememberMe(true);
        } else {
            setEmail('');
            setRememberMe(false);
        }
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        if (isSubmitted) {
            setEmailError('');
            setIsSubmitted(false);
        }
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        if (isSubmitted) {
            setPasswordError('');
            setEmailError('');
            setIsSubmitted(false);
        }
    };

    return (
        <div className="footer_Style_0">
            <div className="fs0_topNaviBar">
                <div className="fs0_logo_container">
                    <img src="/icons/web_icon/Logo.png" alt="MediKnocks Logo" className="logo-image" />
                </div>
            </div>
            <div className="LoginPage_div-7">
                <div className="div">
                    <img src="/icons/web_icon/Login_welcome.png" className="LoginWelComeImg" alt="welcom_icon" />
                    <div className="div-2">
                        <div className="div-4">Please sign in to continue.</div>
                        <form onSubmit={handleSubmit}>
                            <div className={`input-container ${isSubmitted && emailError ? 'error' : ''} ${email ? 'input-filled' : ''} ${isEmailFocused ? 'input-focused' : ''}`}>
                                <div className="div-5">
                                    <img src="/icons/web_icon/img_email.png" className="lg_img" alt="Email icon" />
                                    <div className="input-wrapper">
                                        <input
                                            type="text"
                                            className="div-6"
                                            placeholder="Email Address"
                                            value={email}
                                            onChange={handleEmailChange}
                                            onFocus={handleEmailFocus}
                                            onBlur={() => setIsEmailFocused(false)}
                                        />
                                        {email && (
                                            <button type="button" className="btnClear" onClick={handleClearEmail}>
                                                ×
                                            </button>
                                        )}
                                    </div>
                                </div>
                                <div className="error-text">{emailError}</div>
                            </div>
                            <div className={`input-container ${isSubmitted && passwordError ? 'error' : ''} ${password ? 'input-filled' : ''} ${isPasswordFocused ? 'input-focused' : ''}`}>
                                <div className="div-8">
                                    <img src="/icons/web_icon/img_password.png" className="lg_img" alt="Password icon" />
                                    <div className="input-wrapper">
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            className="div-9"
                                            placeholder="Password"
                                            value={password}
                                            onChange={handlePasswordChange}
                                            onFocus={handlePasswordFocus}
                                            onBlur={() => setIsPasswordFocused(false)}
                                        />
                                        {password && (
                                            <button type="button" className="toggle-password" onClick={handleTogglePassword}>
                                                <img
                                                    src={showPassword ? "/icons/web_icon/img_hiddenPassword.png" : "/icons/web_icon/img_showPassword.png"}
                                                    alt={showPassword ? "Hide password" : "Show password"}
                                                    className="password-toggle-icon"
                                                />
                                            </button>
                                        )}
                                    </div>
                                </div>
                                <div className="error-text">{isSubmitted ? passwordError : ''}</div>
                            </div>
                            <div className="login-options">
                                <label className="remember-me">
                                    <input
                                        type="checkbox"
                                        checked={rememberMe}
                                        onChange={(e) => setRememberMe(e.target.checked)}
                                    />
                                    <span className="checkmark"></span>
                                    <span className="remember-me-text">Remember me</span>
                                </label>
                                <div className="div-11" onClick={() => navigate('/ResetPassword')}>
                                    Forgot Password?
                                </div>
                            </div>
                            <button className="div-15 active" type="submit">
                                Log in
                            </button>
                        </form>
                        <div className="sign-up">
                            Don't have an account? <span className="sign-up_text" onClick={() => navigate('/SignUp')}>Sign up</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;