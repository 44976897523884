import React, { useState } from 'react';
import NTFCallComponent from './NTFCallComponent';
import '../css/NotificationComponent.css';

const CompressedCallList = ({ userCalls, handleAcceptCall, handleCancelCall }) => (
  <div className="notification-popover">

      {userCalls.map(userCall => (
        <NTFCallComponent
          key={userCall.id}
          userCall={userCall}
          handleAcceptCall={() => handleAcceptCall(userCall)}
          handleCancelCall={() => handleCancelCall(userCall)}
        />
      ))}
  </div>
);

const NotificationComponent = ({ userCalls, handleAcceptCall, handleCancelCall, isChatActive }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleNotificationClick = () => {
    if (isChatActive) {
      window.location.reload();
    }
  };

  return (
    <div
      className="notification-container"
      onMouseEnter={() => isChatActive && setIsHovered(true)}
      onMouseLeave={() => isChatActive && setIsHovered(false)}
    >
      <button className="wcc-notification-button" onClick={handleNotificationClick}>
        <img src="./icons/web_icon/notification.png" alt="TTABONG" className="wcc-rate-img" />
        {isChatActive && userCalls.length > 0 && <span className="notification-badge">{userCalls.length}</span>}
      </button>
      {isChatActive && isHovered && (
        <CompressedCallList 
          userCalls={userCalls} 
          handleAcceptCall={handleAcceptCall} 
          handleCancelCall={handleCancelCall}
        />
      )}
    </div>
  );
};

export default NotificationComponent;