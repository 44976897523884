import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Viewer, Worker, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '../css/PatientHistory.css';
import BackArrow from './icons/img_rpBackArrow.png'

// const IP = 'localhost';
const IP = process.env.REACT_APP_HOST;
function PatientHistory({ userCall, onBack, onPatientSearch }) {
  const [groupedPrescriptions, setGroupedPrescriptions] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDates, setOpenDates] = useState({});
  const [openPDFs, setOpenPDFs] = useState({});

  const {
    selectMedicalDep,
    writeSymptom,
    userName,
    age,
    userMedication,
    gender,
    userAdrugeffect,
    userMedicalhistory,
    iconImage,
    user_etc
  } = userCall;

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: () => [], // 사이드바 비활성화
  });

  const customStyles = {
    '.rpv-default-layout__body': {
      paddingTop: 0,
    },
    '.rpv-default-layout__container': {
      height: '100%',
    },
    '.rpv-core__viewer': {
      height: '100%',
    },
    '.rpv-default-layout__main': {
      width: '100%',
      left: 0,
    },
    '.rpv-default-layout__sidebar': {
      display: 'none',
    },
  };

  const toggleDate = (date) => {
    setOpenDates(prev => ({
      ...prev,
      [date]: !prev[date]
    }));
  };

  useEffect(() => {
    const fetchPrescriptions = async () => {
      if (!userCall || (!userCall.userId && !userCall.userEmail)) {
        setError('User information is missing');
        setLoading(false);
        return;
      }

      try {
        const response = await axios.post('/DrChatMain/get-pdf', {
          userEmail: userCall.userEmail || userCall.userId
        });

        if (response.data.pdfUrl && Array.isArray(response.data.pdfUrl)) {
          const prescriptionsWithDates = response.data.pdfUrl
            .map(item => {
              const dateMatch = item.prescription.match(/(\d{4}-\d{2}-\d{2})/);
              const date = dateMatch ? dateMatch[1] : null;
              const pdfUrl = item.prescription.startsWith('http') ? item.prescription : `/${item.prescription}`;
              return {
                url: pdfUrl,
                date: date
              };
            })
            .filter(item => item.date !== null)
            .sort((a, b) => new Date(b.date) - new Date(a.date));

          // 날짜별로 처방전 그룹화
          const grouped = prescriptionsWithDates.reduce((acc, prescription) => {
            if (!acc[prescription.date]) {
              acc[prescription.date] = [];
            }
            acc[prescription.date].push(prescription);
            return acc;
          }, {});

          setGroupedPrescriptions(grouped);
        } else {
          setGroupedPrescriptions({});
        }
        setLoading(false);
      } catch (err) {
        console.error('Error fetching prescriptions:', err);
        setError('처방전 기록을 불러오는데 실패했습니다.');
        setLoading(false);
      }
    };

    fetchPrescriptions();
  }, [userCall]);

  const togglePDF = (date, index) => {
    setOpenPDFs(prev => ({
      ...prev,
      [date]: prev[date] === index ? null : index
    }));
  };

  if (loading) return <div className="loading">로딩 중...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="patient-history">
      <div className="PatientHistory_div-13-2" style={{alignItems:'center'}}>
        <button style={{padding: '0px'}} className="back-button" onClick={onBack}>
          <img src={BackArrow} style={{width: '37px', height: '37px'}} alt="backArrow"/>
        </button>
        <div className='ph_title'>Past prescription record</div>
      </div>
      <div className="PatientInfo_box">
        <img
          loading="lazy"
          srcSet={`/icons/departments/${iconImage}_DeActive.png`}
          alt={`${selectMedicalDep} icon`}
          className="PatientInfo_img"
        />
        <div className="PatientInfo_div-4">{selectMedicalDep}</div>
      </div>
      <div className="PatientInfo_div-12">
        <div className="PatientHistory_div-13">
          <div className="PatientInfo_div-14">{userName}</div>
          <div className="PatientInfo_div-15">({age}, {gender})</div>
        </div>
      </div>
      {Object.keys(groupedPrescriptions).length === 0 ? (
        <p className="no-history">처방전 기록이 없습니다.</p>
      ) : (
        <ul className="history-list">
          {Object.entries(groupedPrescriptions).map(([date, prescriptions]) => (
            <li key={date} className="history-item">
              <div className="history-header" onClick={() => toggleDate(date)}>
                <span className={`toggle-arrow ${openDates[date] ? 'close' : 'open'}`}>
                  {openDates[date] ? '▼' : '▶'}
                </span>
                <span>{date}</span>
              </div>
              {openDates[date] && (
                <div className="pdf-list">
                  {prescriptions.map((prescription, index) => (
                    <div key={index} className="pdf-preview">
                      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                        <div className="pdf-container">
                          <Viewer
                            fileUrl={prescription.url}
                            plugins={[defaultLayoutPluginInstance]}
                            styles={customStyles}
                            defaultScale={0.5}
                            renderLoader={(percentages) => (
                              <div className="loading">Loading... {Math.round(percentages)}%</div>
                            )}
                          />
                        </div>
                      </Worker>
                      <button className='PatientHistoryButton' onClick={() => onPatientSearch(prescription.url)}>
                      <img src="./icons/web_icon/img_patientSearch.png" className='img_patientSearch' alt="Patient Search" />
                    </button>
                    </div>
                  ))}
                </div>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default PatientHistory;