import React, { useState } from 'react';
import Modal from './Modal';
import sadIcon from '../icons/sadicon.png';
//import '../../css/StopTreatmentModal.css';
import './StopTreatmentModal.css';
import axios from 'axios';

// const IP = 'localhost';
const IP = process.env.REACT_APP_HOST;
const StopTreatmentModal = ({ isOpen, onClose, onConfirm, channelUrl, drEmail, userEmail }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post('/ChatEnd', {
        userEmail,
        drEmail,
        channelUrl
      });
  
      if (response.status === 200) {
        console.log('Treatment stopped successfully');
        await new Promise(resolve => setTimeout(resolve, 1000));
        onConfirm();
      }
    } catch (error) {
      console.error('Error stopping treatment:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="stop-treatment-modal">
        <img src={sadIcon} alt="Sad Icon" className="sad-icon" />
        <p className="modal-text">Do you want to finish the treatment?</p>
        <div className="modal-buttons">
          <button className="st_cancel-button" onClick={onClose}>Cancel</button>
          <button 
            className={`confirm-button ${isLoading ? 'loading-button' : ''}`}
            onClick={handleConfirm}
            disabled={isLoading}
          >
            <span className={`button-text ${isLoading ? 'loading' : ''}`}>
              Yes
            </span>
            {isLoading && <div className="loading-spinner"></div>}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default StopTreatmentModal;