import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { getYear, getMonth } from 'date-fns';
import range from 'lodash/range';
import "react-datepicker/dist/react-datepicker.css";
import '../css/CustomDatePicker.css';

const CustomDatePicker = ({ selected, onChange, hasError }) => {
  const [internalDate, setInternalDate] = useState(null);

  useEffect(() => {
    setInternalDate(parseDate(selected));
  }, [selected]);

  const parseDate = (value) => {
    if (!value) return null;
    if (value instanceof Date) return value;
    if (typeof value === 'string') {
      const parts = value.split('/');
      if (parts.length === 3) {
        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1;
        const year = parseInt(parts[2], 10);
        const date = new Date(year, month, day);
        if (isValidDate(date)) return date;
      }
    }
    return null;
  };

  const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date);
  };

  const handleDateChange = (date) => {
    if (isValidDate(date)) {
      setInternalDate(date);
      onChange(formatDate(date));
    }
  };

  const formatDate = (date) => {
    if (!isValidDate(date)) return '';
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  
  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <div className={`custom-date-input ${hasError ? 'has-error' : ''}`} onClick={onClick}>
      <input
        type="text"
        value={value}
        readOnly
        ref={ref}
        placeholder="DD/MM/YYYY"
      />
      <img
        src="/icons/web_icon/img_calendar.png"
        alt="Calendar"
        className="calendar-icon"
      />
    </div>
  ));

  const years = range(1900, getYear(new Date()) - 14, 1);
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  return (
    <div className="custom-date-picker">
      <DatePicker
        selected={internalDate}
        onChange={handleDateChange}
        customInput={<CustomInput hasError={hasError} />}
        dateFormat="dd/MM/yyyy"
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
        }) => (
          <div className="custom-header">
            <select
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(Number(value))}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        )}
      />
    </div>
  );
};

export default CustomDatePicker;