import React from 'react';
import '../css/NTFCallComponent.css';
// const IP = 'localhost';
const IP = process.env.REACT_APP_HOST;
function NTFCallComponent({ userCall, handleAcceptCall }) {
  console.log(userCall.writeSymptom);
  return (
    <div className="NTFCall_div">
    <div className="NTFCall_icon_container">
    <img
        loading="lazy"
        srcSet={"/icons/" + userCall.iconImage + "_DeActive.png"}
        className="NTFCall_icon_img"
      />
    </div>
    <div className="NTFCall_content">
      <div className="NTFCall_header">
        <div className="NTFCall_dept">{userCall.selectMedicalDep}</div>
        <div className="NTFCall_info">{userCall.age} / {userCall.gender}</div>
      </div>
      <textarea className="NTFCall_div-6" value={userCall.writeSymptom} disabled></textarea>
    </div>
  </div>
    
  );
}

export default NTFCallComponent;