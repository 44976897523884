import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { initializeApp, getApps } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
import '../css/ResetPasswordComplete.css'
import '../css/public.css'

const ResetPasswordComplete = () => {
  const location = useLocation();
    const email = new URLSearchParams(location.search).get('email') || '';

    useEffect(() => {
      const firebaseConfig = {
        apiKey: "AIzaSyB-3QdbBue-o9dJcGaLU66f1Yu-2aBgcaU",
        authDomain: "mediknocks-80fa0.firebaseapp.com",
        projectId: "mediknocks-80fa0",
        storageBucket: "mediknocks-80fa0.firebasestorage.app",
        messagingSenderId: "188232379712",
        appId: "1:188232379712:web:bc168fed707c5d10cedea5",
        measurementId: "G-75GKYHXYQY"
      };
  
      let app;
      if (!getApps().length) {
        app = initializeApp(firebaseConfig);
      } else {
        app = getApps()[0];
      }
  
      const analytics = getAnalytics(app);
      const auth = getAuth(app);
    }, []);

    const handleResendEmail = async () => {
        const actionCodeSettings = { 
            url: `${window.location.origin}/SetNewPassword?email=${encodeURIComponent(email)}`, 
            handleCodeInApp: true 
        };
        const auth = getAuth();
        await sendSignInLinkToEmail(auth, email, actionCodeSettings);
        alert('Email has been resent!');
    };

  return (
    <div className="rpc-ForgetPasswordPage_div">
      <div className="fs0_topNaviBar">
        <div className="fs0_logo_container">
          <img src="/icons/web_icon/Logo.png" alt="MediKnocks Logo" className="logo-image" />
        </div>
      </div>

      <div className='rpc_contents'>
        <div className="rpc-ForgetPasswordPage_content">
          <img src="/icons/web_icon/check_45px.png" className="rpc_check_img" alt="check icon" />
          <h1 className="rpc-ForgetPasswordPage_div-8">Password reset email sent</h1>
          <p className="rpc-ForgetPasswordPage_div-9">
            Password reset email instruction successfully sent to '{email}'
          </p>
          <div className='rpc_content_container'>
            <p className="rpc-ForgetPasswordPage_div-3">
              if you not got any email? 
              <button className="rpc-ForgetPasswordPage_div-13" type="button"  onClick={handleResendEmail}>
                Resent
              </button>
            </p>
          <div className="rpc-ForgetPasswordPage_div-14">
            <img src="/icons/web_icon/img_rpBackArrow.png" alt="" className='rpc_lg_img_rp_back'/>
            <a href="/Login" className="rpc-a">Back to Log In</a>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordComplete;