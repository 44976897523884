import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/CompleteSetPassword.css'
import '../css/public.css'
import completeIcon from './icons/CompleteSetPassword_icon.png'

const CompleteSetPasswordPage = () => {
  const navigate = useNavigate();

  const goLoginPage = () => navigate('/Login');

  return (
    <div className="footer_Style_0">
      <div className="fs0_topNaviBar">
        <div className="fs0_logo_container">
          <img src="/icons/web_icon/Logo.png" alt="MediKnocks Logo" className="logo-image" />
        </div>
      </div>
      <div className='rp_contents'>
        <div className='rp_HeaderContainer'>
          <div className="progress-container">
            {[...Array(3)].map((_, index) => (
              <div
                key={index}
                className={`progress-bar ${3 > index ? 'active' : ''}`}
              />
            ))}
          </div>
        </div>
        <img src={completeIcon} style={{ margin: '40px'}} className="rpc_check_img" alt="check icon" />
        <div className="CompleteSetPassword_div-8">Password reset</div>
        <div className="CompleteSetPassword_div-9">
          Your password has been successfully reset.<br />
          Click below to login magically.
        </div>
        <button className="CompleteSetPassword_div-10" onClick={goLoginPage}>
          Login
        </button>
      </div>
    </div>
  );
};

export default CompleteSetPasswordPage;