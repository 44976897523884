import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import axios from 'axios';
import '../css/EmailVerification.css';
import successImage from './icons/img_SignUpSuccess.png'; // Make sure to import the image

function EmailVerification() {
  const [status, setStatus] = useState('Verifying...');
  const [isVerified, setIsVerified] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const verifyEmail = async () => {
      const auth = getAuth();
      if (isSignInWithEmailLink(auth, window.location.href)) {
        const urlParams = new URLSearchParams(window.location.search);
        let email = urlParams.get('email');
        
        if (email) {
          email = decodeURIComponent(email);
        }

        if (email === '${email}') {
          const continueUrl = urlParams.get('continueUrl');
          if (continueUrl) {
            const continueUrlParams = new URLSearchParams(new URL(decodeURIComponent(continueUrl)).search);
            email = continueUrlParams.get('email');
          }
        }

        if (!email) {
          setStatus('Verification cannot be completed without an email.');
          return;
        }

        try {
          await signInWithEmailLink(auth, email, window.location.href);
          const response = await axios.post('/api/verifyEmail', { email });
          if (response.data.success) {
            setStatus('Your email address has been successfully verified');
            setIsVerified(true);
          } else {
            setStatus('Verification failed. Please try again.');
          }
        } catch (error) {
          console.error('Verification error:', error);
          setStatus('An error occurred during the verification process.');
        }
      } else {
        setStatus('Invalid verification link.');
      }
    };

    verifyEmail();
  }, [location]);

  return (
    <div className="emailV_container">
      <div className="emailV_card">
        {isVerified ? (
          <>
            <img src={successImage} alt="Verification Success" className="emailV_successImage" />
            <div className="emailV_title">Email Verification Successful</div>
          </>
        ) : (
          <div className="emailV_title">Email Verification</div>
        )}
        <div className="emailV_status">{status}</div>
      </div>
    </div>
  );
}

export default EmailVerification;