import React, { useState, useRef, useEffect, useCallback } from 'react';
import '../css/SignUp_Main.css';
import '../css/Step3_HospitalInfo.css';
import searchIcon from './icons/search-icon.png';

const US_STATES = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' }
];

function Step3_HospitalInfo({ formData, setFormData, isLoaded, loadError, onBackClick, onNext, touched, setTouched }) {
  const [predictions, setPredictions] = useState([]);
  const [inputStates, setInputStates] = useState({});
  const [errors, setErrors] = useState({});
  
  const [isStateDropdownOpen, setIsStateDropdownOpen] = useState(false);
  const stateDropdownRef = useRef(null);
  const autocompleteService = useRef(null);
  const placesService = useRef(null);

  useEffect(() => {
    if (isStateDropdownOpen) {
      const optionsContainer = document.querySelector('.step3-select-options');
      if (optionsContainer) {
        optionsContainer.scrollTop = 0;
      }
    }
  }, [isStateDropdownOpen]);


  useEffect(() => {
    if (isLoaded && !autocompleteService.current) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
      placesService.current = new window.google.maps.places.PlacesService(document.createElement('div'));
    }
  }, [isLoaded]);

  const handleStateChange = (stateValue) => {
    handleInputChange({ target: { name: 'state', value: stateValue } });
    setIsStateDropdownOpen(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (stateDropdownRef.current && !stateDropdownRef.current.contains(event.target)) {
        setIsStateDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  const setAllFieldsTouched = useCallback(() => {
    setTouched(prev => ({
      ...prev,
      zipCode: true,
      city: true,
      state: true,
      hospitalAddressLine1: true,
      experience: true
    }));
  }, [setTouched]);

  const validateField = useCallback((name, value) => {
    let error = '';
    switch (name) {
      case 'zipCode':
        if (!value) error = 'ZIP Code is required';
        else if (!/^\d{5}(-\d{4})?$/.test(value)) error = 'Invalid ZIP Code format';
        break;
      case 'city':
        if (!value) error = 'City is required';
        break;
      case 'state':
        if (!value) error = 'State is required';
        break;
      case 'hospitalAddressLine1':
        if (!value) error = 'Address line 1 is required';
        break;
      case 'experience':
        if (!value) error = 'Experience is required';
        break;
      default:
        break;
    }
    return error;
  }, []);

  const validateAllFields = useCallback(() => {
    const newErrors = {};
    ['zipCode', 'city', 'state', 'hospitalAddressLine1', 'experience'].forEach(field => {
      const error = validateField(field, formData[field]);
      if (error) {
        newErrors[field] = error;
      }
    });
    setErrors(newErrors);
    console.log('Validation errors:', newErrors);
    return Object.keys(newErrors).length === 0;
  }, [formData, validateField]);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    let newFormData = {
      ...formData,
      [name]: value
    };

    if (name === 'state') {
      const stateObj = US_STATES.find(state => state.value === value);
      if (stateObj) {
        const addressParts = formData.hospitalAddressLine1.split(', ');
        addressParts[addressParts.length - 2] = stateObj.label;
        newFormData.hospitalAddressLine1 = addressParts.join(', ');
      }
    }

    setFormData(newFormData);
    setTouched(prev => ({ ...prev, [name]: true }));
    const error = validateField(name, value);
    setErrors(prev => ({
      ...prev,
      [name]: error
    }));
    updateInputStates(newFormData);
  }, [formData, setFormData, setTouched, validateField]);

  const handleInputFocus = useCallback((name) => {
    setInputStates(prev => ({
      ...prev,
      [name]: 'focused'
    }));
  }, []);

  const handleInputBlur = useCallback((name) => {
    const value = formData[name];
    setInputStates(prev => ({
      ...prev,
      [name]: value && value.length > 0 ? 'filled' : 'empty'
    }));
  }, [formData]);

  const handleAddressInputChange = useCallback((e) => {
    const value = e.target.value;
    setFormData(prev => ({ ...prev, searchAddress: value }));
    if (value.length > 0 && autocompleteService.current) {
      autocompleteService.current.getPlacePredictions(
        { input: value, componentRestrictions: { country: 'us' } },
        (results, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setPredictions(results);
          }
        }
      );
    } else {
      setPredictions([]);
    }
  }, [setFormData]);

  const getAddressComponent = useCallback((components, type) => {
    const component = components.find(comp => comp.types.includes(type));
    return component ? component.long_name : '';
  }, []);

  const handleSelectPlace = useCallback((placeId, e) => {
    if (placesService.current) {
      placesService.current.getDetails(
        { placeId: placeId },
        (place, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK && place) {
            const addressComponents = place.address_components || [];
            const newState = getAddressComponent(addressComponents, 'administrative_area_level_1');
            const newStateValue = US_STATES.find(state => state.label === newState)?.value || '';
            const newFormData = {
              ...formData,
              searchAddress: place.formatted_address || '',
              zipCode: getAddressComponent(addressComponents, 'postal_code'),
              city: getAddressComponent(addressComponents, 'locality'),
              state: newStateValue,
              hospitalAddressLine1: place.formatted_address || '',
              hospitalAddressLine2: '',
            };
            setFormData(newFormData);

            setTouched(prev => ({
              ...prev,
              zipCode: true,
              city: true,
              state: true,
              hospitalAddressLine1: true
            }));

            setErrors({});
            setPredictions([]);
            updateInputStates(newFormData);
            e.target.blur();
          }
        }
      );
    }
  }, [formData, setFormData, setTouched, getAddressComponent]);

  const updateInputStates = useCallback((data) => {
    const newInputStates = {};
    Object.keys(data).forEach(key => {
      if (data[key] != null) {
        newInputStates[key] = data[key].length > 0 ? 'filled' : 'empty';
      } else {
        newInputStates[key] = 'empty';
      }
    });
    setInputStates(newInputStates);
  }, []);

  const handleNextClick = useCallback(() => {
    console.log('Next button clicked');
    setAllFieldsTouched();
    const isValid = validateAllFields();
    console.log('Form is valid:', isValid);
    if (isValid) {
      console.log('Calling onNext function');
      onNext(3); // Pass the current step number
    } else {
      console.log('Form validation failed');
      // Scroll to the first error message
      const firstErrorElement = document.querySelector('.step3-error-text');
      if (firstErrorElement) {
        firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [setAllFieldsTouched, validateAllFields, onNext]);
  

  return (
    <div className="step3-container">
      <div className='step3-title'>Your Information</div>
      <p className="step3-subtitle">Please introduce your career</p>

      <div className="step3-address-section">
        <h3 className="step3-section-title">Your hospital (or home) address</h3>
        <div className="step3-search-container">
          <input
            type="text"
            placeholder="Search my address"
            value={formData.searchAddress || ''}
            onChange={handleAddressInputChange}
            className="step3-address-search-input"
            style={{
              backgroundImage: `url(${searchIcon})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '10px center',
              backgroundSize: '15px',
              paddingLeft: '30px'
            }}
          />

          {predictions.length > 0 && (
            <ul className="step3-predictions-list">
              {predictions.map((prediction) => (
                <li
                  key={prediction.place_id}
                  onClick={(e) => handleSelectPlace(prediction.place_id, e)}
                  className="step3-prediction-item"
                >
                  {prediction.description}
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className="step3-address-details">
        <div className="step3-input-wrapper">
          <input
            type="text"
            name="zipCode"
            placeholder="Zip Code"
            value={formData.zipCode}
            onChange={handleInputChange}
            onFocus={() => handleInputFocus('zipCode')}
            onBlur={() => handleInputBlur('zipCode')}
            className={`step3-input ${inputStates.zipCode || ''} ${touched.zipCode && errors.zipCode ? 'error' : ''}`}
            style={{marginBottom: '5px'}}
          />
          {touched.zipCode && errors.zipCode && <div className="step3-error-text">{errors.zipCode}</div>}
        </div>
        <div className="step3-input-wrapper">
          <input
            type="text"
            name="city"
            placeholder="City"
            value={formData.city}
            onChange={handleInputChange}
            onFocus={() => handleInputFocus('city')}
            onBlur={() => handleInputBlur('city')}
            className={`step3-input ${inputStates.city || ''} ${touched.city && errors.city ? 'error' : ''}`}
            style={{marginBottom: '5px'}}
          />
          {touched.city && errors.city && <div className="step3-error-text">{errors.city}</div>}
        </div>
       <div className="step3-input-wrapper" ref={stateDropdownRef}>
  <div 
    style={{marginBottom: '5px'}}
    className={`step3-custom-select ${isStateDropdownOpen ? 'open' : ''} ${inputStates.state || ''} ${touched.state && errors.state ? 'error' : ''}`}
    onClick={() => setIsStateDropdownOpen(!isStateDropdownOpen)}
  >
    <span className="step3-select-value">
      {formData.state ? US_STATES.find(state => state.value === formData.state)?.label : 'State'}
    </span>
  </div>
  {isStateDropdownOpen && (
    <div className="step3-select-options">
      {US_STATES.map(state => (
        <div
          key={state.value}
          className="step3-select-option"
          onClick={() => handleStateChange(state.value)}
        >
          {state.label}
        </div>
      ))}
    </div>
  )}

{touched.state && errors.state && <div className="step3-error-text">{errors.state}</div>}
         
</div>
      </div>
        <div className="step3-input-group">
          <img src="/icons/web_icon/img_location.png" className="step3-input-icon" alt="Location icon" />
          <input
            type="text"
            name="hospitalAddressLine1"
            placeholder="Hospital Address line 1"
            value={formData.hospitalAddressLine1}
            onChange={handleInputChange}
            onFocus={() => handleInputFocus('hospitalAddressLine1')}
            onBlur={() => handleInputBlur('hospitalAddressLine1')}
            className={`step3-address-input ${inputStates.hospitalAddressLine1 || ''} ${touched.hospitalAddressLine1 && errors.hospitalAddressLine1 ? 'error' : ''}`}
          />
        </div>
        {touched.hospitalAddressLine1 && errors.hospitalAddressLine1 &&
          <div className="step3-error-text">{errors.hospitalAddressLine1}</div>}

        <div className="step3-input-group">
          <img src="/icons/web_icon/img_location.png" className="step3-input-icon" alt="Location icon" />
          <input
            type="text"
            name="hospitalAddressLine2"
            placeholder="Hospital Address line 2 (optional)"
            value={formData.hospitalAddressLine2}
            onChange={handleInputChange}
            onFocus={() => handleInputFocus('hospitalAddressLine2')}
            onBlur={() => handleInputBlur('hospitalAddressLine2')}
            className={`step3-address-input ${inputStates.hospitalAddressLine2 || ''}`}
          />
        </div>
      </div>

      <div className="step3-career-section">
        <h3 className="step3-section-title">Your Career</h3>
        <textarea
          name="experience"
          placeholder="Please describe your medical career. This information will also be shared with patients."
          value={formData.experience}
          onChange={handleInputChange}
          onFocus={() => handleInputFocus('experience')}
          onBlur={() => handleInputBlur('experience')}
          className={`step3-textarea ${inputStates.experience === 'focused' ? 'focused' : ''} ${errors.experience && touched.experience ? 'error' : ''}`}
        />
        {touched.experience && errors.experience &&
          <div className="step3-error-text">{errors.experience}</div>}
      </div>
      <div className='button_center'>
        <button
          type="button"
          onClick={handleNextClick}
          className="st3-next-button"
        >
          Next
          </button>
      </div>
    </div>
  );
}

export default Step3_HospitalInfo;