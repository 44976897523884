import React from 'react';
import '../css/SignUp_Main.css';
import '../css/Step4_MedicalDepartment.css';

const departments = [
  { name: "Allergy and Immunology", icon: 'Allergy_and_Immunology' },
  { name: "Cardiology", icon: 'Cardiology' },
  { name: "Cardiothoracic Surgery", icon: 'Cardiothoracic_Surgery'},
  { name: "Dermatology", icon: 'Dermatology' },
  { name: "Emergency Medicine", icon: 'Emergency_Medicine' },
  { name: "Endocrinology", icon: 'Endocrinology' },
  { name: "Family Medicine", icon: 'Family_Medicine' },
  { name: "Gastroenterology", icon: 'Gastroenterology' },
  { name: "General Surgery", icon: 'General_Surgery' },
  { name: "Hematology", icon: 'Hematology' },
  { name: "Infectious Disease", icon: 'Infectious_Disease' },
  { name: "Internal Medicine", icon: 'Internal_Medicine' },
  { name: "Nephrology", icon: 'Nephrology' },
  { name: "Neurology", icon: 'Neurology' },
  { name: "Neurosurgery", icon: 'Neurosurgery' },
  { name: "Obstetrics and Gynecology", icon: 'Obstetrics_and_Gynecology' },
  { name: "Oncology", icon: 'Oncology' },
  { name: "Ophthalmology", icon: 'Ophthalmology' },
  { name: "Orthopedic Surgery", icon: 'Orthopedic_Surgery' },
  { name: "Orthopedics", icon: 'Orthopedic_Surgery' },
  { name: "Otolaryngology / ENT", icon: 'Otolaryngology_ENT'},
  { name: "Pediatrics", icon: 'Pediatrics' },
  { name: "Physical Medicine and Rehabilitation", icon: 'Physical_Medicine_Rehabilitation' },
  { name: "Plastic Surgery", icon: 'Plastic_Surgery' },
  { name: "Psychiatry", icon: 'Psychiatry' },
  { name: "Pulmonary Medicine", icon: 'Pulmonary_Medicine' },
  { name: "Rheumatology", icon: 'Rheumatology' },
  { name: "Urology", icon: 'Urology' }
 ];

function Step4_MedicalDepartment({ formData, setFormData, onBackClick, onNext }) {
  const handleDepartmentChange = (department) => {
    const updatedDepartments = formData.departments.includes(department)
      ? formData.departments.filter(dep => dep !== department)
      : [...formData.departments, department];
    setFormData({ ...formData, departments: updatedDepartments });
  };

  return (
    <div className='step4-container'>
      <div className='st4_su_h2'>Medical department</div>
      <div className="st4_subtitle">Please check the available medical departments.</div>
      <div className="st4_department-container">
        <div className="st4_department-grid">
          {departments.map((dept) => (
            <button
              type='button'
              key={dept.name}
              className={`st4_department-button ${formData.departments.includes(dept.name) ? 'active' : ''}`}
              onClick={() => handleDepartmentChange(dept.name)}
            >
              <img 
                src={`./icons/departments/${dept.icon}${formData.departments.includes(dept.name) ? '_Active' : '_DeActive'}.png`} 
                alt={dept.name} 
                className="st4_department-icon"
              />
              <span className="st4_department-name">{dept.name}</span>
            </button>
          ))}
        </div>
      </div>
      <div className='st4_button_center '>
        <button type="button" onClick={onNext} className="next-button">
          Next
        </button>
      </div>
    </div>
  );
}

export default Step4_MedicalDepartment;