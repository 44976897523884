import React, { useState } from 'react';
import '../css/SignUp_Main.css';
import '../css/Step6_SelfIntroduction.css';

const MAX_LENGTH = 111500;

function Step6_SelfIntroduction({ formData, setFormData, errors, setErrors, touched, setTouched, onNext }) {
  const [isLoading, setIsLoading] = useState(false);
  const [inputStates, setInputStates] = useState({
    selfIntroduction: 'initial',
    closingRemarks: 'initial'
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (value.length <= MAX_LENGTH) {
      setFormData({
        ...formData,
        [name]: value
      });
      setInputStates(prev => ({
        ...prev,
        [name]: value.length > 0 ? 'active' : 'initial'
      }));
      validateField(name, value);
    }
  };

  const validateField = (fieldName, value) => {
    let errorMessage = '';
    if (value.trim() === '') {
      errorMessage = `${fieldName === 'selfIntroduction' ? 'Self-introduction' : 'Closing remarks'} is required`;
    }
    setErrors(prevErrors => ({
      ...prevErrors,
      [fieldName]: errorMessage
    }));
  };

  const handleBlur = (fieldName) => {
    setTouched(prev => ({
      ...prev,
      [fieldName]: true
    }));
    validateField(fieldName, formData[fieldName]);
    if (formData[fieldName].trim() === '') {
      setInputStates(prev => ({
        ...prev,
        [fieldName]: 'error'
      }));
    }
  };

  const handleFocus = (fieldName) => {
    setInputStates(prev => ({
      ...prev,
      [fieldName]: 'active'
    }));
  };

  const handleNext = async () => {
    const newTouched = { selfIntroduction: true, closingRemarks: true };
    setTouched(newTouched);
    
    validateField('selfIntroduction', formData.selfIntroduction);
    validateField('closingRemarks', formData.closingRemarks);
    
    if (formData.selfIntroduction.trim() === '' || formData.closingRemarks.trim() === '') {
      setErrors(prevErrors => ({
        ...prevErrors,
        selfIntroduction: formData.selfIntroduction.trim() === '' ? 'Self-introduction is required' : '',
        closingRemarks: formData.closingRemarks.trim() === '' ? 'Closing remarks is required' : ''
      }));
      setInputStates(prev => ({
        ...prev,
        selfIntroduction: formData.selfIntroduction.trim() === '' ? 'error' : prev.selfIntroduction,
        closingRemarks: formData.closingRemarks.trim() === '' ? 'error' : prev.closingRemarks
      }));
      return;
    }
    
    setIsLoading(true);
    
    try {
      await onNext(formData);
      await new Promise(resolve => setTimeout(resolve, 2000));
    } finally {
      setIsLoading(false);
    }
  };


  const renderTextArea = (fieldName, label, placeholder) => (
    <div className="st6_input-group">
      <label htmlFor={fieldName}>{label}</label>
      <textarea
        id={fieldName}
        name={fieldName}
        value={formData[fieldName]}
        onChange={handleInputChange}
        onFocus={() => handleFocus(fieldName)}
        onBlur={() => handleBlur(fieldName)}
        placeholder={placeholder}
        maxLength={MAX_LENGTH}
        className={`st6_textarea ${inputStates[fieldName]} ${touched[fieldName] && errors[fieldName] ? 'error' : ''}`}
      />
      {touched[fieldName] && errors[fieldName] && (
        <div className="st6_error-message">{errors[fieldName]}</div>
      )}
    </div>
  );

  return (
    <div className="step6-container">
      <h1 className="st6_title">Self-Introduction</h1>
      <p className="st6_subtitle">Please introduce yourself, such as your career, for your patients.</p>
      
      {renderTextArea(
        'selfIntroduction',
        'Self-Introduction',
        'Enter your greeting for the patient. This greeting will be shown to the patient.'
      )}
      {renderTextArea(
        'closingRemarks',
        'Closing Remarks',
        'Enter your closing remarks for the patient. This closing remark will be shown to the patient.'
      )}
      
      <div className='button_center'>
        <button 
          type="button" 
          className={`st6_submit-button ${isLoading ? 'st6_loading-button' : ''}`}
          onClick={handleNext}
          disabled={isLoading}
          style={{marginTop: '20px'}}
        >
          <span className={`st6_button-text ${isLoading ? 'st6_loading' : ''}`}>
            OK
          </span>
          {isLoading && <div className="st6_loading-spinner"></div>}
        </button>
      </div>
    </div>
  );
}

export default Step6_SelfIntroduction;