import React from 'react';
import '../css/Sidebar.css'

const Sidebar = ({ handleLogoutClick, onNavigate, activeMenu }) => {
  const menuItems = [
    { name: 'editProfile', label: 'Edit Profile', path: '/editProfile', icon: 'img_EditProfile' },
    { name: 'account', label: 'Account', path: '/account', icon: 'img_Account' },
    { name: 'income', label: 'Income', path: '/pay', icon: 'img_income' },
    { name: 'help', label: 'Help', path: '/help', icon: 'img_help' },
    { name: 'logout', label: 'Logout', path: null, icon: 'img_logout' }
  ];

  const handleBackClick = () => {
    onNavigate('/WebChat', 'webChat'); // 두 번째 파라미터는 메뉴 이름을 나타냄
  };

  return (
    <aside className="dpp-sidebar">
      <nav>
        <button onClick={handleBackClick} className="dpp-back-button">
          &lt;
        </button>
        {menuItems.map((item) => (
          <button
            key={item.name}
            className={`dpp-menubutton ${activeMenu === item.name ? 'active' : ''}`}
            onClick={() => item.name === 'logout' ? handleLogoutClick() : onNavigate(item.path)}
          >
            <span className="dpp-menuText">
              <img
                className='dpp-menuImg'
                src={`../../icons/web_icon/${item.icon}_${activeMenu === item.name ? 'Active' : 'DeActive'}.png`}
                alt=""
              />
              {item.label}
            </span>
          </button>
        ))}
      </nav>
    </aside>
  );
};

export default Sidebar;