import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Viewer } from '@react-pdf-viewer/core';
import { pdf } from '@react-pdf/renderer';
import PrescriptionDocument from './PrescriptionDocument';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import * as pdfjs from 'pdfjs-dist';
import { Worker as PDFWorker } from '@react-pdf-viewer/core';
import axios from 'axios';

const IP = process.env.REACT_APP_HOST;

const Preview = ({ data, channelUrl, sb, onClose, onSend, drEmail, userCall }) => {
  const [pdfBlob, setPdfBlob] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [drData, setDrData] = useState(null);
  const [userInfo, setUserInfo] = useState(null);

  const fetchDrInfo = useCallback(async () => {
    try {
      const response = await axios.post(`/Save/getDrInfo`, { drEmail }, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json' },
      });
      setDrData(response.data.dr);
    } catch (error) {
      console.error('Error fetching doctor info:', error);
      setError('Failed to fetch doctor information.');
    }
  }, [drEmail]);

  const fetchUserInfo = useCallback(async () => {
    try {
      const userId = userCall.userId;
      const response = await axios.post(`/getUserInfo`, { userEmail: userId }, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json' },
      });
      setUserInfo(response.data.user);
    } catch (error) {
      console.error('Error fetching user info:', error);
      setError('Failed to fetch user information.');
    }
  }, [userCall.userId]);

  useEffect(() => {
    fetchDrInfo();
    fetchUserInfo();
  }, [fetchDrInfo, fetchUserInfo]);

  const generatePdf = useCallback(async () => {
    if (!drData || !userInfo) return null;
    try {
      return await pdf(<PrescriptionDocument data={data} userInfo={userInfo} drInfo={drData} />).toBlob();
    } catch (error) {
      console.error('Error generating PDF:', error);
      setError('Failed to generate PDF. Please try again.');
      return null;
    }
  }, [data, userInfo, drData]);

  const memoizedPdfBlob = useMemo(async () => {
    setIsLoading(true);
    const blob = await generatePdf();
    setIsLoading(false);
    return blob;
  }, [generatePdf]);

  useEffect(() => {
    memoizedPdfBlob.then(setPdfBlob);
  }, [memoizedPdfBlob]);

  const pdfUrl = useMemo(() => pdfBlob ? URL.createObjectURL(pdfBlob) : null, [pdfBlob]);

  useEffect(() => {
    return () => {
      if (pdfUrl) URL.revokeObjectURL(pdfUrl);
    };
  }, [pdfUrl]);

  const handleSendPdf = async () => {
    if (!pdfBlob || !sb) {
      setError('PDF or chat is not ready. Please try again.');
      return;
    }

    try {
      setIsLoading(true);

      const fileName = `prescription_${userInfo.user_email}_${new Date().toISOString().slice(0, 10)}.pdf`;
      const date = new Date().toISOString().slice(0, 10);
      const time = new Date().toISOString().slice(11, 19).replace(/:/g, '-');
      const fileNameSv = `prescription_${userInfo.user_email}_${date}_${time}.pdf`;

      const formData = new FormData();
      formData.append('prescription', pdfBlob, fileNameSv);
      formData.append('diseaseName', data.diagnosisName);
      formData.append('channelUrl', channelUrl);
      formData.append('userEmail', userInfo.user_email);
      formData.append('drEmail', drEmail);
      formData.append('fileName', fileName);

      const serverResponse = await axios.post(`/ChatEnd/prescription`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (serverResponse.status !== 200) {
        throw new Error('Failed to send data to server');
      }

      const channel = await sb.GroupChannel.getChannel(channelUrl);
      if (!channel) {
        throw new Error('Failed to get channel');
      }

      const file = new File([pdfBlob], fileName, { type: 'application/pdf' });

      const fileMessageParams = new sb.FileMessageParams();
      fileMessageParams.file = file;
      fileMessageParams.fileName = fileName;
      fileMessageParams.fileSize = file.size;
      fileMessageParams.mimeType = 'application/pdf';

      await channel.sendFileMessage(fileMessageParams);

      localStorage.removeItem('prescriptionFormDiagnosis');
      localStorage.removeItem('prescriptionFormIssue');
      localStorage.removeItem('prescriptionFormDrugs');

      onClose();
      onSend();
    } catch (error) {
      console.error('Error sending PDF and diagnosis:', error);
      const errorMessage = error.response?.data?.details || error.message;
      setError(`Failed to send prescription and diagnosis. Error: ${errorMessage}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="Prev_modal-overlay">
      <div className="Prev_modal-content">
        {isLoading && (
          <div className="Prev_loading-overlay">
            <div className="Prev_loading-spinner"></div>
          </div>
        )}
        {error && <div className="Prev_error-message">{error}</div>}
        <h2 className="Prev_title">Preview</h2>
        <div className="Prev_pdf-viewer-container">
          {pdfUrl ? (
            <PDFWorker workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`}>
              <Viewer
                fileUrl={pdfUrl}
                defaultScale={1.3}
                key={pdfUrl}
              />
            </PDFWorker>
          ) : (
            <div>Failed to load PDF file.</div>
          )}
        </div>
        <div className="Prev_button-container">
          <button onClick={onClose} className="Prev_cancel-button" disabled={isLoading}>
            Back
          </button>
          <button
            onClick={handleSendPdf}
            className={`Prev_send-button ${isLoading ? 'Prev_loading' : ''}`}
            disabled={isLoading}
          >
            {isLoading ? 'Sending...' : 'Send'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Preview;