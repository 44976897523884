import React, { useState } from 'react';
import '../css/UserCallComponent.css';
const IP = process.env.REACT_APP_HOST;
function UserCallComponent({ userCall, handleAcceptCall, handleCancelCall }) {
  const [isLoading, setIsLoading] = useState(false);
  const handleClick = async () => {
    setIsLoading(true);
    try {
      await handleAcceptCall();
    } finally {
      setIsLoading(false);
    }
  };

  console.log(userCall.writeSymptom);
  return (
    <div className="UserCall_div">
      <img
        loading="lazy"
        srcSet={"/icons/" + userCall.iconImage + "_DeActive.png"}
        className="UserCall_icon_img"
      />
      <div className="UserCall_div-3">
        <div className="UserCall_div-5">{userCall.selectMedicalDep}</div>
        <div className="UserCall_div-4">Age: {userCall.age} / {userCall.gender}</div>
      </div>
      <textarea className="UserCall_div-6" value={userCall.writeSymptom} disabled></textarea>
      <button 
        className={`UserCall_div-7 ${isLoading ? 'UserCall_loading-button' : ''}`}
        onClick={handleClick}
        disabled={isLoading}
      >
        <span className={`UserCall_button-text ${isLoading ? 'UserCall_loading' : ''}`}>
          OK
        </span>
        {isLoading && <div className="UserCall_loading-spinner"></div>}
      </button>
    </div>
  );
}

export default UserCallComponent;