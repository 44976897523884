import React, { useEffect, useState } from 'react';
import '../css/ChannelList.css';
import SendBird from 'sendbird';
import axios from 'axios';

const sb = new SendBird({ appId: process.env.REACT_APP_APP_ID });
const IP = process.env.REACT_APP_HOST;

const ChannelListComponent = ({ 
  channels, 
  onSelectChannel, 
  activeTab, 
  inactiveDiagnoses, 
  unreadCounts, 
  currentUser, 
  getChannelName,
  selectedChannelUrl  // 새로운 prop 추가
}) => {
  const [channelDetails, setChannelDetails] = useState({});

  useEffect(() => {
    const fetchChannelDetails = async () => {
      const details = {};

      for (const channel of channels) {
        try {
          const fetchedChannel = await sb.GroupChannel.getChannel(channel.url);
          const response = await axios.post(`/api/getUserCallInfo`, {
            channelUrl: channel.url,
          });
    
          console.log("Channel details for", channel.url, ":", response.data);
          console.log("inactiveDiagnoses is : "+ inactiveDiagnoses[channel.url]);
   
          const res_chatPrgrs = response.data.chatPrgrs && response.data.chatPrgrs[0];
          const res_userCall = res_chatPrgrs && res_chatPrgrs.userCall && res_chatPrgrs.userCall[0];
    
          if (res_userCall) {
            details[channel.url] = {
              userName: res_userCall.userName,
              age: res_userCall.age,
              gender: res_userCall.gender,
              icon: res_chatPrgrs.iconImage,
              isFrozen: fetchedChannel.isFrozen,
              selectMedicalDep: res_userCall.selectMedicalDep,
              userDisease: res_chatPrgrs.userDisease,
            };
          } else {
            const chatEndResponse = await axios.post(`/api/getChatEndInfo`, {
              channelUrl: channel.url,
            });
            if (chatEndResponse.data.chatEnd) {
              details[channel.url] = {
                userName: chatEndResponse.data.chatEnd.userName,
                age: chatEndResponse.data.chatEnd.age,
                gender: chatEndResponse.data.chatEnd.gender,
                icon: chatEndResponse.data.chatEnd.iconImage,
                isFrozen: true,
                selectMedicalDep: chatEndResponse.data.chatEnd.selectMedicalDep,
                userDisease: chatEndResponse.data.chatEnd.userDisease,
              };
            }
          }
        } catch (error) {
          console.error('Error fetching channel details for', channel.url, ':', error);
        }
      }

      for (const channel of channels) {
        try {
          const fetchedChannel = await sb.GroupChannel.getChannel(channel.url);
          details[channel.url].isFrozen = fetchedChannel.isFrozen;
        } catch (error) {
          console.error('Error fetching channel frozen status:', error);
        }
      }

      setChannelDetails(details);
    };

    fetchChannelDetails();
  }, [channels, currentUser, inactiveDiagnoses]);

  const measureText = (text, font) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = font;
    return context.measureText(text).width;
  };

  const renderChannelName = (channel) => {
    const maxWidth = 55;
    const fontSize = 20;
    const fontWeight = 600;
    const fontFamily = 'Baloo Paaji 2, -apple-system, Roboto, Helvetica, sans-serif';
    const font = `${fontWeight} ${fontSize}px ${fontFamily}`;
    
    let fullName = getChannelName(channel, currentUser ? currentUser.userId : '');

    fullName = fullName.replace('()', '').trim();
    const lastOpenBracket = fullName.lastIndexOf('(');
     
    if (lastOpenBracket !== -1) {
      const name = fullName.substring(0, lastOpenBracket).trim();
      const details = fullName.substring(lastOpenBracket).trim();
      fullName = `${name} ${details}`;
    }
    const textWidth = measureText(fullName, font);
    const className = textWidth > maxWidth ? 'channel-list-name fixed-width' : 'channel-list-name min-content';
    
    return (
      <div className={className} title={fullName}>
        {fullName}
      </div>
    );
  };

  const getChannelClassName = (channelUrl) => {
    const baseClass = 'channel-list-item';
    const isSelected = channelUrl === selectedChannelUrl;
    return `${baseClass}${isSelected ? ' selected' : ''}`;
  };

  return (
    <div className="channel-list">
      {channels.map(channel => {
        const details = channelDetails[channel.url] || {};
        const iconUrl = `/icons/${details.icon || 'default'}_Active.png`;
        const isSelected = channel.url === selectedChannelUrl;
        
        return (
          <div 
            key={channel.url} 
            className={getChannelClassName(channel.url)}
            onClick={() => onSelectChannel(channel.url)}
            style={isSelected ? { backgroundColor: '#b2ebf2' } : {}}
          >
            <div className="channel-list-content">
              <div className="channel-list-icon">
                <img
                  loading="lazy"
                  src={iconUrl}
                  className="channel-list-icon-image"
                  alt="Channel icon"
                />
              </div>
              <div className="channel-list-details">
                <div className="channel-list-info">
                  <div className="channel-list-name-box">
                    <div className="channel-list-name-container">
                      {renderChannelName(channel)}
                      <div className="channel-list-user-info">
                        ({details.age || '0'}, {details.gender || 'N/A'})
                      </div>
                      <div className={`channel-list-status ${details.isFrozen ? 'channel-list-status-inactive' : 'channel-list-status-active'}`} />
                    </div>
                  </div>
                  <div className="channel-list-department"> 
                  {details.isFrozen 
                      ? `diagnostic name: ${details.userDisease || 'Not available'}` 
                      : (`in medical treatment`  || 'Not available')}
                  </div>
                </div>
              </div>
            </div>
            {unreadCounts[channel.url] > 0 && (
              <span className="channel-list-unread-badge">{unreadCounts[channel.url]}</span>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ChannelListComponent;