import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EditProfile_NTFCallComponent from './EditProfile_NTFCallComponent';
import '../css/NotificationComponent.css';

const CompressedCallList = ({ userCalls }) => (
  
  <div className="notification-popover" style={{cursor: "default"}}>
    {userCalls.map(userCall => (
      <EditProfile_NTFCallComponent
        key={userCall.id}
        userCall={userCall}
      />
    ))}
  </div>
);

const DoctorProfileNotification = ({ userCalls, handleAcceptCall, handleCancelCall }) => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/WebChat');
  };

  return (
    <div
      className="notification-container"
    >
      <button 
        className="wcc-notification-button"
        onClick={handleClick}
      >
        <img src="./icons/web_icon/notification.png" alt="TTABONG" className="wcc-rate-img" />
        {userCalls.length > 0 && <span className="notification-badge">{userCalls.length}</span>}
      </button>
      {isHovered && userCalls.length > 0 && (
        <CompressedCallList 
          userCalls={userCalls} 
        />
      )}
    </div>
  );
};

export default DoctorProfileNotification;