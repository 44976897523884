import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/StartPage.css';
import '../css/public.css';

function StartPage() {
    const navigate = useNavigate();
    const [menuVisible, setMenuVisible] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleGetStarted = async() => {
        navigate('/Login');
        try {
            const response = await axios.get(`/api/session-email`, {
                headers: {
                  'Content-Type': 'application/json',
                },
                withCredentials: true,
              });

            if (response.data.email) {
                navigate('/WebChat');
            } else {
                navigate('/Login');
            }
        } catch (error) {
            console.error("Error checking session:", error);
            navigate('/Login');
        }
    };

     // 메뉴 표시/숨기기 토글 함수
    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
      };

    return (
        <div className="start-page-footer_Style_overflow">
            <div className="start-page-fs0_topNaviBar">
                <div className="start-page-fs0_logo_container">
                    <img src="/icons/web_icon/Logo.png" alt="MediKnocks Logo" className="Start-page-logo-image" />
                    <div class="start-page-nav-wrapper">
                        <div class="start-page-sl-nav">
                            <img
                                src="/icons/web_icon/language.png"
                                alt="language icon"
                                className="start-page-language-icon"
                            />
                            <ul>
                                <li onClick={toggleDropdown} className="start-page-dropdown-trigger">
                                Español <i className="fa fa-angle-down" aria-hidden="true"></i>
                                <div className="start-page-triangle"></div>
                                {isDropdownOpen && (
                                    <ul className="start-page-dropdown-menu">
                                    <li>
                                        <a href = "/"><span>English</span></a>
                                    </li>
                                    <li>
                                        <span className="start-page-active">Español</span>
                                    </li>
                                    </ul>
                                )}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="start-page-nav-wrapper-400">
                        <div className="start-page-sl-nav">
                            <img onClick={toggleDropdown}
                                src="/icons/web_icon/language.png"
                                alt="language icon"
                                className="start-page-language-icon"
                            />
                            <ul>
                                <li onClick={toggleDropdown} className="start-page-dropdown-trigger">
                                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                                    <div className="start-page-triangle-400"></div>
                                    {isDropdownOpen && (
                                        <ul className="start-page-dropdown-menu-400">
                                        <li>
                                            <a href = "/"><span>English</span></a>
                                        </li>
                                        <li>
                                            <span className="start-page-active">Español</span>
                                        </li>
                                        </ul>
                                    )}
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/**1000px 이하에서만 보임 */}
                    <img 
                        src="/icons/web_icon/menu_45px.png" 
                        alt="menu Icon Image" 
                        className="start-page-menu-image"
                        onClick={toggleMenu}
                    />
                </div>

                 {/* 메뉴 컨테이너 */}
                <div className={`fs0_MenuContainerMobile ${menuVisible ? 'visible' : ''}`}>
                <button className="start-page-close-btn" onClick={toggleMenu}>X</button>
                    {/* 메뉴 내용 */}
                    <ul className="start-page-menu-list">
                    <li><a href="/AboutUs/ES" className="start-page-menu-list-fs0_text">Sobre Nosotros</a></li>
                    <li><a href="/HowHealthy" className="start-page-menu-list-fs0_text">Evento</a></li>
                    </ul>
                </div>

                <nav className="fs0_MenuContainer">
                    <a href="/AboutUs/ES" className="fs0_text">Sobre Nosotros</a>
                    <a href="/HowHealthy" className="fs0_text">Evento</a>
                    {/* <a href="#user-app" className="fs0_text">User App</a>
                    <a href="#doctor-guide" className="fs0_text">Doctor Guide</a> */}
                </nav>
            </div>
            <main className="start-page-main">
                <section id="start-page-main-here" className="start-page-hero">
                    <div className="start-page-hero-content">
                        <h2 className="start-page-hero-h2">Rápido y <br/> Fácilmente <span className="start-page-mediknocks-main"><br />MediKnocks</span></h2>
                        <p className="start-page-p">Reduciendo <b>distancias</b> y <b>aumentando</b> los servicios de salud</p>
                        <div className="start-page-btn-group">
                            <a href = "https://apps.apple.com/us/app/mediknocks/id6737291462"><img src="/icons/web_icon/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" alt="apple Logo" className="start-page-cta-button1" /></a>
                            <a href = "https://play.google.com/store/apps/details?id=com.mediknocksapp.login"><img src="/icons/web_icon/google-play-badge.png" alt="google Logo" className="start-page-cta-button2" /></a> 
                            <button className="start-page-cta-button3" onClick={handleGetStarted}>I'm a Doctor</button>
                        </div>
                    </div>
                    <div className="start-page-image">
                        <img src="/icons/web_icon/img_start1.png" alt="Doctors with patient" />
                    </div>
                </section>
                <section id="section05" class="demo">
                        <a href="#start-page-what-is">
                            <span></span>Scroll
                        </a>
                </section>
            </main>
            <main className="start-page-main-content">
                <section id = "start-page-what-is" className="start-page-what-is">
                    <h2 className="start-page-h2">Ahora, <span className="mediknocks">llama</span> en cualquier momento para tu salud</h2>
                    <div className="what-is-content1">
                        <img className= "start-page-img-2" src="/icons/web_icon/img_start2.png" alt="Doctor" />
                    </div>
                </section>
                <hr className="start-page-content-line"></hr>
                
                <section className="start-page-what-is2">
                <h2 className="start-page-h2">¿Te sientes saludable hoy?<br/>
                    Si necesitas consultar con un doctor, solo necesitas <span className="mediknocks">descargar una aplicación</span></h2>
                    <div className="what-is-content">
                        <img class="start-page-img-3-1" src="/icons/web_icon/img_start3.png" alt="Doctor" />
                        <div className="start-page-what-is-text text_right">
                            <div className="st_div_fontUP2">
                                <br />En MediKnocks, doctores
                                <br />de diversas especialidades ya están esperando.
                                <br />Conectamos a los pacientes que buscan tratamiento 
                                <br />con el doctor adecuado de la manera más sencilla posible.
                            </div>
                            <div className="try-now-btn2">
                                <a href="#start-page-main-here">Prueba ahora →</a>
                            </div>
                        </div>
                        <img class="start-page-img-3-2" src="/icons/web_icon/img_start3.png" alt="Doctor"/>
                    </div>
                </section>
                <hr className="start-page-content-line"></hr>

                <section className="start-page-what-is">
                    <h2 className="start-page-h2">Consulta fácilmente con un doctor a través de <span className="mediknocks">chat</span></h2>
                    <div className="what-is-content3">
                        <div className="start-page-what-is-text3">
                            <div className="st_div_fontUP3">
                                Sin las limitaciones de tiempo y espacio, 
                                <br />tanto los doctores como los pacientes pueden realizar consultas 
                                <br />en cualquier momento y en cualquier lugar, a través de nuestro servicio.
                            </div>
                            <div className="try-now-btn3">
                                <a href="#start-page-main-here">Prueba ahora →</a>
                            </div>
                        </div>
                        <img className = "start-page-img-4" src="/icons/web_icon/img_start4.png" alt="Doctor" />
                    </div>
                </section>
                <hr className="start-page-content-line"></hr>

                <section className="start-page-what-is4">
                    <h2 className="start-page-h2">No te preocupes si no hablas inglés<br/>
                    <span className="mediknocks">MediKnocks lo traducirá automáticamente para ti</span></h2>
                    <div className="what-is-content3">
                        <div className="start-page-what-is-text3">
                            <div className="st_div_fontUP3">
                                Haz preguntas en varios idiomas. 
                                <br />Ya no dudes sobre el tratamiento por no hablar inglés.
                            </div>
                            <div className="try-now-btn3">
                                <a href="#start-page-main-here">Prueba ahora →</a>
                            </div>
                        </div>
                        <img className = "start-page-img-5" src="/icons/web_icon/img_start5.png" alt="Doctor" />
                    </div>
                </section>
            </main>
            <section className="start-page-last-bar">
                <div className="start-page-last-bar-text">
                    <div className="start-page-last-bar-text-first">
                        ¿Estás listo para comenzar una <br/>
                        <span className="start-page-last-bar-text-sec">
                        nueva consulta? <br/>
                        </span>
                    </div>
                    <div className="start-page-last-btn">
                        <a href="#start-page-main-here"><button className="start-page-cta-button4">Obtén la app</button></a>
                        <br />
                        <button className="start-page-cta-button5" onClick={handleGetStarted}>Iniciar como Dr</button>
                    </div>
                </div>
            </section>

            <footer className="footer">
                <div className="footer-content">
                    <div className="footer-section">
                    <img src="/icons/web_icon/Logo.png" alt="MediKnocks Logo" className="footer-logo-image" />
                        <h3>Headquarters</h3>
                        <p>
                            MediKnocks, Inc. <br/>
                            5708 Sean Paul Lane <br/>
                            Georgetown Texas (78628) <br/><br/>
                            ⓒ 2022 MediKnocks, Inc.
                        </p>
                    </div>
                    <div className="footer-section">
                        <h3 className="footer-section-title">Sobre nosotros</h3>
                        <a href="/AboutUs/ES"><p>Sobre nosotros</p></a>
                        <a href= "/HowHealthy"><p>Evento</p></a>
                    </div>
                    <div className="footer-section">
                        <h3>Soporte al cliente y comentarios</h3>
                        <p>Correo electrónico: <a href="mailto:support@mediknocks.com" style={{color:"#01b6cd"}}>support@mediknocks.com</a></p>
                    </div>

                    <a href ="https://www.instagram.com/mediknocks/?fbclid=IwZXh0bgNhZW0CMTEAAR0IPClgflIEwm9gMrGz5EaOxbgdDMIFb2-TDzG8fOrX3qwRJ1cMXQXXrY4_aem_z93n3KVVwYD01rMLdd2dzQ"><img src="/icons/web_icon/Instagram_Glyph_Gradient.png" alt="instagram" className="start-page-sns-button1"/></a>
                    <a href = "https://www.facebook.com/profile.php?id=61567539849561"><img src="/icons/web_icon/Facebook_Logo_Primary.png" alt="facebook" className="start-page-sns-button1"/></a>
                </div>
            </footer>
        </div>
    );
}

export default StartPage;