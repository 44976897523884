import React from 'react';
import '../css/MedicalDepartmentModal.css'; // 새로운 CSS 파일을 만들어야 합니다

const departments = [
  { name: "Allergy and Immunology", icon: 'Allergy_and_Immunology' },
  { name: "Cardiology", icon: 'Cardiology' },
  { name: "Cardiothoracic Surgery", icon: 'Cardiothoracic_Surgery'},
  { name: "Dermatology", icon: 'Dermatology' },
  { name: "Emergency Medicine", icon: 'Emergency_Medicine' },
  { name: "Endocrinology", icon: 'Endocrinology' },
  { name: "Family Medicine", icon: 'Family_Medicine' },
  { name: "Gastroenterology", icon: 'Gastroenterology' },
  { name: "General Surgery", icon: 'General_Surgery' },
  { name: "Hematology", icon: 'Hematology' },
  { name: "Infectious Disease", icon: 'Infectious_Disease' },
  { name: "Internal Medicine", icon: 'Internal_Medicine' },
  { name: "Nephrology", icon: 'Nephrology' },
  { name: "Neurology", icon: 'Neurology' },
  { name: "Neurosurgery", icon: 'Neurosurgery' },
  { name: "Obstetrics and Gynecology", icon: 'Obstetrics_and_Gynecology' },
  { name: "Oncology", icon: 'Oncology' },
  { name: "Ophthalmology", icon: 'Ophthalmology' },
  { name: "Orthopedic Surgery", icon: 'Orthopedic_Surgery' },
  { name: "Orthopedics", icon: 'Orthopedic_Surgery' },
  { name: "Otolaryngology / ENT", icon: 'Otolaryngology_ENT'},
  { name: "Pediatrics", icon: 'Pediatrics' },
  { name: "Physical Medicine and Rehabilitation", icon: 'Physical_Medicine_Rehabilitation' },
  { name: "Plastic Surgery", icon: 'Plastic_Surgery' },
  { name: "Psychiatry", icon: 'Psychiatry' },
  { name: "Pulmonary Medicine", icon: 'Pulmonary_Medicine' },
  { name: "Rheumatology", icon: 'Rheumatology' },
  { name: "Urology", icon: 'Urology' }
];

function MedicalDepartmentModal({ isOpen, onClose, selectedDepartments, onDepartmentChange, onSave }) {
  if (!isOpen) return null;

  return (
    <div className="mdm-overlay">
      <div className="mdm-content">
        <h2 className="mdm-title">Medical department</h2>
        <p className="mdm-subtitle">Please check the available medical departments.</p>
        <div className="mdm-department-container">
          <div className="mdm-department-grid">
            {departments.map((dept) => (
              <button
                type="button"
                key={dept.name}
                className={`mdm-department-button ${selectedDepartments.includes(dept.name) ? 'active' : ''}`}
                onClick={() => onDepartmentChange(dept.name)}
              >
                <img 
                  src={`./icons/departments/${dept.icon}${selectedDepartments.includes(dept.name) ? '_Active' : '_DeActive'}.png`} 
                  alt={dept.name} 
                  className="mdm-department-icon"
                />
                <span className="mdm-department-name">{dept.name}</span>
              </button>
            ))}
          </div>
        </div>
        <div className="mdm-button-center">
          <button type="button" onClick={onClose} className="mdm-cancel-button">
            Cancel
          </button>
          <button type="button" onClick={onSave} className="mdm-save-button">
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default MedicalDepartmentModal;