import React from 'react';
import  warningIcon from '../pages/icons/warningIcon.png';
import '../css/DiagnosisAlert.css'; // CSS 파일 import
const DiagnosisAlert= ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
       <div className="diagnosis-alert-modal">
         <div className="di_modal-content">
          <div className='di_center'>
            <img src={warningIcon} alt="Warning" className="warning-icon" />
            <h2 className="di_modal-title">Please diagnose first</h2>
            <p className="di_modal-message">
              The consultation cannot be concluded until the <br />diagnosis and prescription have been sent.
            </p>
            <button onClick={onClose} className="di_ok-button">
              OK
            </button>
           </div>
         </div>
       </div>
     );
   };

export default DiagnosisAlert;