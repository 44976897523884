import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import SendBird from 'sendbird';
import '../css/AccountSection.css';
import '../css/DoctorProfile.css';
import LogoutModal from './LogoutModal';
import logoImage from './icons/Logo.png';
import notificationIcon from './icons/notification.png';
import Sidebar from './Sidebar';
import DoctorProfileNotification from './DoctorProfileNotification';

const IP = process.env.REACT_APP_HOST;

const sb = new SendBird({ appId: 'D3977B5D-9B96-4530-A606-88DA7E02906A' });

const AccountSection = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [activeMenu, setActiveMenu] = useState('account');
  const [data, setData] = useState({});
  const email = localStorage.getItem('email');
  const navigate = useNavigate();
  const callSound = new Audio('/OccureCall.wav');
  const [sound, setSound] = useState();
  const [userCalls, setUserCalls] = useState([]);

  const menuItems = [
    { name: 'editProfile', label: 'Edit Profile', path: '/editProfile' },
    { name: 'account', label: 'Account', path: '/account' },
    { name: 'income', label: 'Income', path: '/pay' },
    { name: 'help', label: 'Help', path: '/help' }
  ];

  const handleSetNewPassword = () => {
    navigate('/AccountResetPassword');
  };

  const fetchData = async () => {
    try {
      const response = await axios.post(`/Save/getDrInfo`, { drEmail: email }, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      });

      let fetchedData = response.data.dr;
      setSound(fetchedData.dr_soundActive || false);

      // Ensure dr_medical_department is always an array
      if (fetchedData.dr_medical_department) {
        if (typeof fetchedData.dr_medical_department === 'string') {
          fetchedData.dr_medical_department = fetchedData.dr_medical_department.split(',').map(dept => dept.trim());
        } else if (!Array.isArray(fetchedData.dr_medical_department)) {
          console.error('Unexpected type for dr_medical_department:', typeof fetchedData.dr_medical_department);
          fetchedData.dr_medical_department = [];
        }
      } else {
        fetchedData.dr_medical_department = [];
      }

      setData(fetchedData);
      console.log(fetchedData);
      console.log("DoctorProfile getData!!");
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleNavigate = (path, menuName) => {
    if (path === -1) {
      navigate(-1);
    } else {
      navigate(path);
      setActiveMenu(menuName);
    }
  };

  const handleSave = async () => {
    setError('');
    if (password !== confirmPassword) {
      setError("Passwords don't match");
      return;
    }

    try {
      const response = await axios.post(`/doctor/updatePassword`, {
        email,
        newPassword: password
      }, {
        withCredentials: true
      });

      if (response.data.success) {
        alert('Password updated successfully');
        setPassword('');
        setConfirmPassword('');
      } else {
        setError('Failed to update password');
      }
    } catch (error) {
      console.error('Error updating password:', error);
      setError('An error occurred while updating your password');
    }
  };

  const handleLeaveAccount = async () => {
    if (window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
      try {
        const response = await axios.post(`/doctor/deleteAccount`, {
          email
        }, {
          withCredentials: true
        });

        if (response.data.success) {
          alert('Your account has been deleted successfully');
          window.location.href = '/login';
        } else {
          setError('Failed to delete account');
        }
      } catch (error) {
        console.error('Error deleting account:', error);
        setError('An error occurred while deleting your account');
      }
    }
  };

  const handleLogoutClick = () => {
    setShowLogoutModal(true);
  };

  const handleLogoutConfirm = async () => {
    try {
      await axios.post('/Login/logout', {}, { withCredentials: true });
      await sb.disconnect();
      window.location.href = '/Login';
    } catch (error) {
      console.error('Error logout:', error);
      alert('Failed to logout');
    } finally {
      setShowLogoutModal(false);
    }
  };
  const goMain = () =>{
    navigate('/WebChat');
    //window.location.reload();
  }

  const toggleSound = async () => {
    try {
      // DB 업데이트
      await axios.post('/Save/updateSound', {
        email: email,
        soundActive: !sound
      }, {
        withCredentials: true
      });
  
      // 상태 업데이트
      setSound(!sound);
      
      // 음소거 상태 업데이트
      if (callSound) {
        callSound.muted = sound;
      }
    } catch (error) {
      console.error('Error updating sound settings:', error);
    }
  };

  const handleAcceptCall = async (userCall) => {
    try {
      const response = await axios.post('/DrChatMain/callAccept', {
        userEmail: userCall.userId,
        drEmail: email,
      });

      if (response.status === 200) {
        navigate('/WebChat');
      }
    } catch (error) {
      console.error('Error accepting user call:', error);
      if (error.response && error.response.status === 400) {
        alert('This call has expired');
      }
    }
  };

  const handleCancelCall = (userCall) => {
    console.log('Call cancelled:', userCall);
  };

  useEffect(() => {
    const eventSource = new EventSource(`/api/sse?email=${email}`);
    
    eventSource.onmessage = (event) => {
      try {
        const newEvent = JSON.parse(event.data);
        if (newEvent.length > userCalls.length) {
          if (callSound) {
            callSound.muted = !sound; // sound 상태에 따라 음소거 설정
            callSound.play().catch(error => {
              console.error('Error playing sound:', error);
            });
          }
        }
        setUserCalls(newEvent);
      } catch (e) {
        console.error('Failed to parse event data:', e);
      }
    };
  
    eventSource.onerror = (error) => {
      console.error('EventSource failed: ', error);
      eventSource.close();
    };
  
    return () => {
      eventSource.close();
    };
  }, [email, userCalls.length, sound]);

  return (
    <div className="dpp-doctor-profile">
      <div className="wcc-header">
        <div className="wcc-header-left">
        <button onClick={goMain} style={{background: 'none', border: 'none', cursor: 'pointer'}}>
            <img src={logoImage} alt="MEDINOX Logo" className="wcc-logo-image" />
          </button>
        </div>
        <div className="wcc-header-right">
          <button 
              className='wcc_sounds'
              onClick={toggleSound}
              style={{display:"contents"}}>
              <img 
                src={sound ? "./icons/web_icon/sound_on.png" : "./icons/web_icon/sound_mute.png"} 
                alt={sound ? "Sound On" : "Sound Off"} 
                style={{width:"32px", height:"auto"}}
              />
            </button>
            <button className="wcc-notification-button">
              <DoctorProfileNotification
                userCalls={userCalls}
                handleAcceptCall={handleAcceptCall}
                handleCancelCall={handleCancelCall}
              />
            </button>
          <div className="wcc-rate-container">
            <img src="./icons/web_icon/img_rate.png" alt="TTABONG" className="wcc-rate-img" />
            <div className="wcc-rate-text"><span style={{ color: "#01B6CD" }}>{data.dr_rating}</span> / 5</div>
          </div>
          <button className="wcc-drprofile-button">
            <img
              loading="lazy"
              src={data.dr_profile_photo}
              alt="Doctor Profile"
              className="wcc-drprofile-img"
            />
          </button>
        </div>
      </div>
      <div className="dpp-content">
        <Sidebar
          handleLogoutClick={handleLogoutClick} 
          onNavigate={handleNavigate}
          activeMenu={activeMenu}
          menuItems={menuItems}
        />
        <main className="as_dpp-main-content">
          <div className="account-section">
            <div className='dp_MainTitle'>Account</div>

            {error && <div className="error-message">{error}</div>}

            <div className="input-group">
              <div className='as_label'>Email Address</div>
              <div className="as-input-wrapper">
              <label htmlFor="as_birthdate">Email</label>
              <input
                type="text"
                className='dp_textarea_B'
                name="dr_birthdate"
                id="dr_birthdate"
                value={email}
                disabled 
                placeholder="Email"
              />
            </div>
            </div>
            <div className="input-group">
              <div className='dp_label'>Password</div>
              <button 
                className="as_changePwBtn"
                onClick={handleSetNewPassword}
              >
                Set New Password
              </button>
            </div>

            <button className="As_delete-account">
              Delete Account
            </button>
          </div>
        </main>
      </div>
      {showLogoutModal && (
        <LogoutModal
          onClose={() => setShowLogoutModal(false)}
          onConfirm={handleLogoutConfirm}
        />
      )}
    </div>
  );
};

export default AccountSection;