import React, { useState, useEffect, forwardRef } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import '../css/SignUp_Main.css';
import '../css/Step2_PersonalInfo.css';
import CustomDatePicker from './CustomDatePicker';

function Step2_PersonalInfo({ formData, setFormData, errors, setErrors, touched, setTouched, onBackClick, onNext }) {
  const [isFormValid, setIsFormValid] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [profilePicturePreview, setProfilePicturePreview] = useState(null);

  const figtreeStyle = { fontFamily: 'Figtree, sans-serif' };

  useEffect(() => {
    validateForm();
  }, [formData]);

  useEffect(() => {
    // formData에 profilePicture가 있고, 미리보기가 없는 경우에만 설정
    if (formData.profilePicture && !profilePicturePreview) {
      // File 객체인 경우 미리보기 URL 생성
      if (formData.profilePicture instanceof File) {
        setProfilePicturePreview(URL.createObjectURL(formData.profilePicture));
      }
      // 이미 URL인 경우 그대로 사용
      else if (typeof formData.profilePicture === 'string') {
        setProfilePicturePreview(formData.profilePicture);
      }
    }
  }, [formData.profilePicture]);

  useEffect(() => {
    return () => {
      if (profilePicturePreview && profilePicturePreview.startsWith('blob:')) {
        URL.revokeObjectURL(profilePicturePreview);
      }
    };
  }, [profilePicturePreview]);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = "First Name is required";
    if (!formData.lastName) newErrors.lastName = "Last Name is required";
    if (!formData.dateOfBirth) newErrors.dateOfBirth = "Date of birth is required";
    if (!formData.gender) newErrors.gender = "Gender is required";
    if (!formData.phoneNumber) {
      newErrors.phoneNumber = "Phone number is required";
    } else if (formData.phoneNumber.replace(/\D/g, '').length !== 10) {
      newErrors.phoneNumber = "Please enter a valid 10-digit phone number";
    }
    if (!formData.doctorType) newErrors.doctorType = "Doctor type is required";
    if (!formData.profilePicture) newErrors.profilePicture = "Profile picture is required";
  
    setErrors(newErrors);
    const isValid = Object.keys(newErrors).length === 0;
    setIsFormValid(isValid);
    return isValid;
  };

  const handleFocus = (name) => {
    setErrors(prev => ({
      ...prev,
      [name]: ''
    }));
  };
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
    setTouched(prev => ({ ...prev, [name]: true }));
  };

  const handleDateChange = (date) => {
    setFormData(prevData => ({
      ...prevData,
      dateOfBirth: date
    }));
    setTouched(prev => ({ ...prev, dateOfBirth: true }));
  };

  const handlePhoneNumberChange = (e) => {
    const input = e.target.value.replace(/\D/g, '');
    let formattedNumber = '';
    if (input.length > 0) {
      formattedNumber = '(' + input.substr(0, 3);
      if (input.length > 3) {
        formattedNumber += ') ' + input.substr(3, 3);
      }
      if (input.length > 6) {
        formattedNumber += '-' + input.substr(6, 4);
      }
    }
    setFormData(prevData => ({
      ...prevData,
      phoneNumber: formattedNumber
    }));
    setTouched(prev => ({ ...prev, phoneNumber: true }));
  };

  const handleDoctorTypeChange = (type) => {
    setFormData(prevData => ({
      ...prevData,
      doctorType: type,
      hospitalAddress: type === 'unaffiliated' ? '' : prevData.hospitalAddress
    }));
    setTouched(prev => ({ ...prev, doctorType: true }));
  };

  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData(prevData => ({
        ...prevData,
        profilePicture: file
      }));
      setTouched(prev => ({ ...prev, profilePicture: true }));
      setProfilePicturePreview(URL.createObjectURL(file));
    }
  };
  const handleNextClick = () => {
    setNextClicked(true);
    setTouched({
      firstName: true,
      lastName: true,
      dateOfBirth: true,
      gender: true,
      phoneNumber: true,
      doctorType: true,
      profilePicture: true
    });
    const formIsValid = validateForm();
    if (formIsValid) {
      onNext();
    } else {
      console.log("Form is invalid. Please check all fields.");
    }
  };

  const renderInput = (name, placeholder, icon, inputType = "text") => (
    <div style={{    marginTop: '5px'}} className={`Step2_input-group ${touched[name] && errors[name] ? 'has-error' : ''}`}>
      {name === "phoneNumber" && (
        <label style={{ fontFamily: "Figtree, sans-serif", fontSize: "15px", fontWeight: "bold" }}>Phone number</label>
      )}
      <div className="Step2_input-wrapper">
        <img
          src={icon}
          className={`Step2_input-icon ${icon.includes('img_drPerson') ? 'Step2_dr-person-icon' : ''}`}
          alt={`${name} icon`}
        />
        <input
          type={inputType}
          name={name}
          placeholder={placeholder}
          value={formData[name]}
          onChange={name === "phoneNumber" ? handlePhoneNumberChange : handleInputChange}
          onFocus={() => handleFocus(name)}
          className="Step2_input-field"
          maxLength={name === "phoneNumber" ? 14 : undefined}
        />
      </div>
    </div>
  );

  return (
    <div className="Step2_container" style={figtreeStyle}>
      <h2 className="Step2_title">Create Account</h2>
      <p className="Step2_subtitle">Please sign up to continue</p>

      <div className="Step2_profile-picture-section">
        <div className="Step2_profile-picture-container">
          {/* {formData.profilePicture ? (
            <img
              src={formData.profilePicture}
              alt="Profile"
              className="Step2_profile-image"
            /> */}
          {profilePicturePreview ? (
            <img
              src={profilePicturePreview}
              alt="Profile"
              className="Step2_profile-image"
            />
          ) : (
            <div className="Step2_profile-picture-placeholder">
              <img
                src="/icons/web_icon/user-icon.png"
                alt="User Icon"
                className="Step2_user-icon"
              />
            </div>
          )}
          <label htmlFor="Step2_profile-upload" className="Step2_camera-icon-overlay">
            <img
              src="/icons/web_icon/img_camera.png"
              alt="Upload"
              className="Step2_camera-icon"
            />
            <input
              id="Step2_profile-upload"
              type="file"
              accept="image/*"
              onChange={handleProfilePictureChange}
              style={{ display: 'none' }}
            />
          </label>
        </div>
      </div>
      {touched.profilePicture && errors.profilePicture && (
        <p className="Step2_error-message_profile">{errors.profilePicture}</p>
      )}

      <div className="Step2_input-section">
        <div className={`Step2_input-group 
    ${errors.firstName && touched.firstName ? 'has-error' : ''}`}>
          <div className="Step2_input-wrapper">
            <img
              src="/icons/web_icon/img_drPerson.png"
              className="Step2_input-icon Step2_dr-person-icon"
              alt="First Name icon"
            />
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              value={formData.firstName}
              onChange={handleInputChange}
              onFocus={() => handleFocus('firstName')}
              className="Step2_input-field"
            />
          </div>
        </div>
        <div className="Step2_error-container">
          {touched.firstName && errors.firstName ? (
            <div className="Step2_error-text">{errors.firstName}</div>
          ) : null}
        </div>
        <div className={`Step2_input-group 
    ${errors.lastName && touched.lastName ? 'has-error' : ''}`}>
          <div className="Step2_input-wrapper">
            <img
              src="/icons/web_icon/img_drPerson.png"
              className="Step2_input-icon Step2_dr-person-icon"
              alt="Last Name icon"
            />
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleInputChange}
              onFocus={() => handleFocus('lastName')}
              className="Step2_input-field"
            />
          </div>
        </div>
        <div className="Step2_error-container">
          {touched.lastName && errors.lastName ? (
            <div className="Step2_error-text">{errors.lastName}</div>
          ) : null}
        </div>

        <div className="Step2_date-gender">
          <div className={`Step2_date-of-birth ${touched.dateOfBirth && errors.dateOfBirth ? 'has-error' : ''}`}>
            <label style={{ fontFamily: "Figtree, sans-serif", fontSize: "15px", fontWeight: "bold" }}>Date of birth</label>
            <CustomDatePicker
              selected={formData.dateOfBirth}
              onChange={handleDateChange}
              hasError={touched.dateOfBirth && errors.dateOfBirth}
            />
            {touched.dateOfBirth && errors.dateOfBirth && (
              <div className="Step2_error-message-date">{errors.dateOfBirth}</div>
            )}
          </div>

          <div className={`Step2_gender-select ${touched.gender && errors.gender ? 'has-error' : ''}`}>
            <label style={{ fontFamily: "Figtree, sans-serif", fontSize: "15px", fontWeight: "bold" }}>Gender</label>
            <select
              name="gender"
              value={formData.gender}
              onChange={handleInputChange}
              onFocus={() => handleFocus('gender')}
              className="Step2_input-field_date"
              style={figtreeStyle}
            >
              <option value="" disabled hidden>Select</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
            {touched.gender && errors.gender && (
              <div className="Step2_error-message_gender">{errors.gender}</div>
            )}
          </div>
        </div>
        {renderInput("phoneNumber", "(000) 000-0000", "/icons/web_icon/img_phone.png", "tel")}
        {nextClicked && errors.phoneNumber && (
          <div className="Step2_error-message">{errors.phoneNumber}</div>
        )}
      </div>

      <div className="Step2_doctor-type-group">
        <label className="Step2_doctor-type-label" style={figtreeStyle}>Please select your affiliation</label>
        {['affiliated', 'clinicOwner', 'unaffiliated'].map((type) => (
          <label key={type} className={`Step2_radio-label ${formData.doctorType === type ? 'selected' : ''}`} style={figtreeStyle}>
            <input
              type="radio"
              name="doctorType"
              value={type}
              checked={formData.doctorType === type}
              onChange={() => handleDoctorTypeChange(type)}
              className="Step2_radio-input"
            />
            {type === 'affiliated' ? 'You are an employed doctor at a hospital' :
              type === 'clinicOwner' ? 'You have an independent practice' :
                'Neither of the above'}
          </label>
        ))}
        {touched.doctorType && errors.doctorType && (
          <div className="Step2_error-message">{errors.doctorType}</div>
        )}
      </div>

      <div className='button_center'>
        <button
          type="button"
          onClick={handleNextClick}
          className="Step2_next-button"
          style={figtreeStyle}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default Step2_PersonalInfo;