import React, { useState } from 'react';
import '../css/NTFCallComponent.css';
const IP = process.env.REACT_APP_HOST;

function NTFCallComponent({ userCall, handleAcceptCall }) {  
  const [isLoading, setIsLoading] = useState(false);
  const handleClick = async () => {
    setIsLoading(true);
    try {
      await handleAcceptCall();
    } finally {
      setIsLoading(false);
    }
  };
  
  return (
    <div className="NTFCall_div">
      <div className="NTFCall_icon_container">
        <img
          loading="lazy"
          srcSet={"/icons/" + userCall.iconImage + "_DeActive.png"}
          className="NTFCall_icon_img"
        />
      </div>
      <div className="NTFCall_content">
        <div className="NTFCall_header">
          <div className="NTFCall_dept">{userCall.selectMedicalDep}</div>
          <div className="NTFCall_info">{userCall.age} / {userCall.gender}</div>
        </div>
        <textarea className="NTFCall_div-6" value={userCall.writeSymptom} disabled></textarea>
      </div>
      <button 
        className={`NTFCall_div-7 ${isLoading ? 'NTFCall_loading-button' : ''}`}
        onClick={handleClick}
        disabled={isLoading}
      >
        <span className={`NTFCall_button-text ${isLoading ? 'NTFCall_loading' : ''}`}>
          OK
        </span>
        {isLoading && <div className="NTFCall_loading-spinner"></div>}
      </button>
    </div>
  );
}

export default NTFCallComponent;