import React, { useRef, useState, useEffect } from 'react';
import '../css/SignUp_Main.css';
import '../css/Step5_LicenseAuthentication.css';
import * as pdfjsLib from 'pdfjs-dist';
// PDF.js worker setup
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

function Step5_LicenseAuthentication({ formData, isLicenseUploaded, setIsLicenseUploaded, setFormData, errors, setErrors, touched, setTouched, onBackClick, onNext }) {
  const fileInputRef = useRef(null);
  const [dragActive, setDragActive] = useState(false);
  const [error, setError] = useState(null);

  const convertPdfToImage = async (file) => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
      const page = await pdf.getPage(1);
      const viewport = page.getViewport({ scale: 1 });
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      const maxWidth = 800;  // 480에서 800으로 증가
      const maxHeight = 600; // 380에서 600으로 증가

      const ratio = Math.min(maxWidth / viewport.width, maxHeight / viewport.height);

      canvas.width = viewport.width * ratio;
      canvas.height = viewport.height * ratio;

      const scaledViewport = page.getViewport({ scale: ratio });

      await page.render({ canvasContext: context, viewport: scaledViewport }).promise;
      return canvas.toDataURL();
    } catch (error) {
      console.error('Error converting PDF to image:', error);
      return null;
    }
  };

  const truncateFileName = (fileName, maxLength = 20) => {
    if (fileName.length <= maxLength) return fileName;
    const extension = fileName.split('.').pop();
    const nameWithoutExt = fileName.substring(0, fileName.lastIndexOf('.'));
    const truncatedName = nameWithoutExt.substring(0, maxLength - 3 - extension.length) + '...';
    return `${truncatedName}.${extension}`;
  };



  const handleFileUpload = async (file) => {
    if (file) {
      setError(null); // 에러 초기화

      // 파일 크기 검사
      if (file.size > 5 * 1024 * 1024) {
        setErrors(prev => ({ ...prev, step5: "File size exceeds 5MB limit." }));
        setIsLicenseUploaded(false);
        return;
      }

      // 파일 타입 검사
      const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];
      if (!allowedTypes.includes(file.type)) {
        setErrors(prev => ({ ...prev, step5: "Invalid file type. Please upload a JPG, PNG, or PDF file." }));
        setIsLicenseUploaded(false);
        return;
      }

      let preview;
      if (file.type === 'application/pdf') {
        preview = await convertPdfToImage(file);
        if (!preview) {
          setErrors(prev => ({ ...prev, step5: "Failed to convert PDF to image." }));
          setIsLicenseUploaded(false);
          return;
        }
      } else {
        preview = URL.createObjectURL(file);
      }

      // 모든 검사를 통과한 후에만 상태 업데이트
      setFormData({
        ...formData,
        licenseFile: file,
        licensePreview: preview
      });
      setIsLicenseUploaded(true);
      setErrors(prev => ({ ...prev, step5: null }));
    }
  };


  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFileUpload(e.dataTransfer.files[0]);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFileUpload(e.target.files[0]);
    }
  };

  const handleNext = () => {
    if (!formData.licenseFile || !isLicenseUploaded) {
      setError("Please upload a license file before proceeding.");
      setErrors(prev => ({ ...prev, step5: "License file is required." }));
      return; // 파일이 없으면 여기서 함수 종료
    }

    setError(null);
    setErrors(prev => ({ ...prev, step5: null }));
    onNext();
  };


  const handleRemoveFile = () => {
    setFormData({
      ...formData,
      licenseFile: null,
      licensePreview: null
    });
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setIsLicenseUploaded(false);
    setErrors(prev => ({ ...prev, step5: null }));
    setError(null);
  };

  useEffect(() => {
    const button = document.querySelector('.st5_upload-button');
    console.log('Button classes:', button.className);
    console.log('Button style:', button.style.cssText);
    console.log('Is disabled:', button.disabled);
  }, [isLicenseUploaded]);


  useEffect(() => {
    console.log('isLicenseUploaded:', isLicenseUploaded);
  }, [isLicenseUploaded])

  return (
    <div className="step5-container">
      <div className='st5_su_h2'>License Authentication</div>
      <p className="subtitle">Please upload the license authentication</p>

      <div
        className={`st5_file-upload-area ${dragActive ? 'drag-active' : ''} ${formData.licensePreview ? 'with-preview' : ''}`}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        onClick={() => fileInputRef.current.click()}
      >
        {formData.licensePreview ? (
          <div className="st5_preview-container">
            <img src={formData.licensePreview} alt="License Preview" className="st5_preview-image" />
          </div>
        ) : (
          <div className="st5_file-upload-area-content">
            <img src="/icons/web_icon/img_lock.png" alt="Upload Icon" className="st5_upload-icon1" />
            <p>Drag and drop an image here or<br />click the upload button to upload your license file (jpg ,pdf only)</p>
          </div>
        )}
      </div>

      <div className="st5_file-actions">
        {formData.licenseFile && (
          <div className="st5_file-info" style={{
            display: 'flex',
            alignItems: 'center',
            maxWidth: 'calc(100% - 120px)', // Upload 버튼 너비 + 여백 고려
            marginLeft: '10px',
            overflow: 'hidden'
          }}>
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {truncateFileName(formData.licenseFile.name)}
            </span>
            <button
              onClick={handleRemoveFile}
              className="st5_remove-file"
              style={{
                marginLeft: '8px',
                flexShrink: 0
              }}
            >
              ×
            </button>
          </div>
        )}
        {isLicenseUploaded ? (
          <button
            className="st5_upload-button uploaded"
            disabled
            style={{
              backgroundColor: '#cccccc',
              color: '#666666',
              borderColor: '#cccccc',
              cursor: 'not-allowed'
            }}
          >
            Uploaded
          </button>
        ) : (
          <button
            onClick={(e) => {
              e.preventDefault();
              fileInputRef.current.click();
            }}
            className="st5_upload-button"
          >
            <img src="/icons/web_icon/img_upload.png" alt="Upload" className="st5_upload-icon" />
            Upload
          </button>
        )}

        <input
          ref={fileInputRef}
          type="file"
          accept=".jpg,.jpeg,.png,.pdf"
          onChange={handleChange}
          style={{ display: 'none' }}
        />


      </div>

      {error && <p className="st5_error-message">{error}</p>}

      <button
        onClick={handleNext}
        className={`st5_next-button ${(!formData.licenseFile || !isLicenseUploaded) ? 'disabled' : ''}`}
        disabled={!formData.licenseFile || !isLicenseUploaded}
      >
        Next
      </button>
    </div>
  );
}

export default Step5_LicenseAuthentication;