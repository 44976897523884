import React, { useState, useEffect } from 'react';
import './PrescriptionForm.css';
import axios from 'axios';

const IP = process.env.REACT_APP_HOST;

const PrescriptionForm = ({ onSubmit, userInfo, onClose, channelUrl, drEmail, sb, onDiagnosisSent }) => {
  const [diagnosisName, setDiagnosisName] = useState(() => {
    const saved = localStorage.getItem('prescriptionFormDiagnosis');
    return saved ? JSON.parse(saved) : '';
  });
  const [issuePrescription, setIssuePrescription] = useState(() => {
    const saved = localStorage.getItem('prescriptionFormIssue');
    return saved ? JSON.parse(saved) : false;
  });
  const [drugFields, setDrugFields] = useState(() => {
    const saved = localStorage.getItem('prescriptionFormDrugs');
    return saved ? JSON.parse(saved) : [{
      drugName: '',
      drugStrength: '',
      dispense: '',
      dosageForm: '',
      directions: '',
      doctorsNote: '',
      refills: 0
    }];
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [contentHeight, setContentHeight] = useState('auto');

  useEffect(() => {
    localStorage.setItem('prescriptionFormDiagnosis', JSON.stringify(diagnosisName));
  }, [diagnosisName]);

  useEffect(() => {
    localStorage.setItem('prescriptionFormIssue', JSON.stringify(issuePrescription));
  }, [issuePrescription]);

  useEffect(() => {
    localStorage.setItem('prescriptionFormDrugs', JSON.stringify(drugFields));
  }, [drugFields]);

  useEffect(() => {
    if (issuePrescription) {
      setContentHeight('auto');
    } else {
      setContentHeight('300px');
    }
  }, [issuePrescription]);

  const clearStoredData = () => {
    localStorage.removeItem('prescriptionFormDiagnosis');
    localStorage.removeItem('prescriptionFormIssue');
    localStorage.removeItem('prescriptionFormDrugs');
  };

  const handleDiagnosisChange = (event) => {
    setDiagnosisName(event.target.value);
  };

  const handleIssuePrescriptionChange = (event) => {
    setIssuePrescription(event.target.checked);
  };

  const handleDrugChange = (index, event) => {
    let data = [...drugFields];
    data[index][event.target.name] = event.target.value;
    setDrugFields(data);
  };

  const handleRefillChange = (index, change) => {
    let data = [...drugFields];
    data[index].refills = Math.max(0, data[index].refills + change);
    setDrugFields(data);
  };

  const addDrugFields = () => {
    setDrugFields([...drugFields, {
      drugName: '',
      drugStrength: '',
      dispense: '',
      dosageForm: '',
      directions: '',
      doctorsNote: '',
      refills: 0
    }]);
  };

  const removeDrugFields = (index) => {
    let data = [...drugFields];
    data.splice(index, 1);
    setDrugFields(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (diagnosisName) {
      setIsLoading(true);
      setError(null);

      try {
        if (!issuePrescription) {
          const response = await axios.post(`/ChatEnd/diagnosis`, {
            drEmail,
            userEmail: userInfo.userId,
            channelUrl,
            diseaseName: diagnosisName
          });
          console.log("Server response:", response.data);
          onDiagnosisSent();
          localStorage.removeItem('prescriptionFormDiagnosis');
          localStorage.removeItem('prescriptionFormIssue');
          localStorage.removeItem('prescriptionFormDrugs');
          onClose();
        } else {
          const submissionData = {
            patientName: userInfo.userName,
            dob: userInfo.age,
            address: userInfo.userId,
            diagnosisName,
            drugs: drugFields
          };
          onSubmit(submissionData);
        }
      } catch (err) {
        console.error('Error submitting form:', err);
        setError(err.response?.data?.message || err.message || 'An unknown error occurred');
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="Pr_overlay">
      <div className={`Pr_content prescription-form ${issuePrescription ? 'expanded' : 'collapsed'}`}>
        <div className="Pr_form-header">
          <div className="Pr_title">Diagnosis & Prescribing</div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="Pr_form-group pr_padding20200">
            <div className="Pr_form-row Pr_diagnosis-name">
              <label className="Pr_label2">Diagnosis name <span className="Pr_required">*</span></label>
              <input
                type="text"
                name="diagnosisName"
                placeholder="Please enter the patient's diagnosis name"
                value={diagnosisName}
                onChange={handleDiagnosisChange}
                required
              />
            </div>
          </div>
          {error && <div className="Pr_error">{error}</div>}
          <div className="Pr_form-row pr_padding020">
            <label className="Pr_checkbox-label">
              <input
                type="checkbox"
                className='pr_checkbox'
                checked={issuePrescription}
                onChange={handleIssuePrescriptionChange}
              />
              To issue a prescription
            </label>
          </div>
          {issuePrescription && (
            <>
              <div className="Pr_drug-section">
                {drugFields.map((drug, index) => (
                  <div key={index} className="Pr_form-group">
                    <label className="Pr_drug-label">Prescription {index + 1}</label>
                    <div className="Pr_form-row">
                      <label className="Pr_left-label pr_margin25">Drug name <span className="Pr_required">*</span></label>
                      <input
                        type="text"
                        name="drugName"
                        className='pr_drugName'
                        placeholder="Please enter the drug name"
                        value={drug.drugName}
                        onChange={(event) => handleDrugChange(index, event)}
                        required
                      />
                    </div>
                    <div className="Pr_form-row">
                      <label className="Pr_left-label pr_margin25">Drug Strength <span className="Pr_required">*</span></label>
                      <div className="Pr_strength-dispense-container">
                        <div className="Pr_strength-container">
                          <input
                            type="text"
                            name="drugStrength"
                            className='pr_drugStrength'
                            placeholder="Units per milligram"
                            value={drug.drugStrength}
                            onChange={(event) => handleDrugChange(index, event)}
                            required
                          />
                        </div>
                        <div className="Pr_dispense-container">
                          <label className="Pr_dispense-label">Dispense <span className="Pr_required">*</span></label>
                          <input
                            type="text"
                            name="dispense"
                            placeholder="Dispense"
                            className='pr_dispense'
                            value={drug.dispense}
                            onChange={(event) => handleDrugChange(index, event)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="Pr_form-row">
                      <label className="Pr_left-label pr_margin25">Dosage form <span className="Pr_required">*</span></label>
                      <input
                        type="text"
                        name="dosageForm"
                        placeholder="Please enter the dosage form"
                        className='pr_width'
                        value={drug.dosageForm}
                        onChange={(event) => handleDrugChange(index, event)}
                        required
                      />
                    </div>
                    <div className="Pr_form-row">
                      <label className="Pr_left-label">Directions for use <span className="Pr_required">*</span></label>
                      <input
                        type="text"
                        name="directions"
                        placeholder="Please enter the directions for use"
                        className='pr_width'
                        value={drug.directions}
                        onChange={(event) => handleDrugChange(index, event)}
                        required
                      />
                    </div>
                    <div className="Pr_form-row">
                      <label className="Pr_left-label pr_margin20">Refills</label>
                      <div className="Pr_refill-control">
                        <button type="button" className="Pr_refill-btn" onClick={() => handleRefillChange(index, -1)} disabled={drug.refills === 0}>-</button>
                        <input
                          type="text"
                          name="refills"
                          value={drug.refills}
                          readOnly
                          className="Pr_refill-input"
                        />
                        <button type="button" className="Pr_refill-btn" onClick={() => handleRefillChange(index, 1)}>+</button>
                      </div>
                    </div>
                    <div className="Pr_form-row">
                      <label className="Pr_left-label pr_margin25">Doctor's Note</label>
                      <textarea
                        name="doctorsNote"
                        placeholder="Please enter the Doctor's Note"
                        value={drug.doctorsNote}
                        onChange={(event) => handleDrugChange(index, event)}
                        className="Pr_prescription-textarea doctors-note"
                        rows="4"
                      />
                    </div>
                    {drugFields.length > 1 && (
                      <button type="button" className="Pr_remove-drug-btn" onClick={() => removeDrugFields(index)}>
                        <img src='./icons/web_icon/img_rmvDrugs.png' className='pr_btnImage '></img>
                        Remove Drug
                      </button>
                    )}
                  </div>
                ))}
              </div>
              <button type="button" onClick={addDrugFields} className="Pr_add-drug-btn">
                <img src='./icons/web_icon/img_addDrugs.png' className='pr_btnImage'></img>
                ADD DRUGS
              </button>
            </>
          )}
          <div className='pr_buttonContainer'>
            <button className="Pr_close-button" onClick={onClose}>Back</button>
            <button
              type="submit"
              className={`Pr_submit-btn ${isLoading ? 'pr_loading-button' : ''}`}
              disabled={isLoading}
            >
              <span className={`pr_button-text ${isLoading ? 'pr_loading' : ''}`}>
                {issuePrescription ? 'Finish' : 'Finish'}
              </span>
              {isLoading && <div className="pr_loading-spinner"></div>}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PrescriptionForm;