import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/AboutUs.css';
import '../css/public.css';

function AboutUs() {
    const [menuVisible, setMenuVisible] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

      // 메뉴 표시/숨기기 토글 함수
      const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
      };

    return (
        <div className="aboutus-page-footer_Style_overflow">
            <div className="aboutus-page-fs0_topNaviBar">
                <div className="aboutus-page-fs0_logo_container">
                    <a href="/"><img src="/icons/web_icon/Logo.png" alt="MediKnocks Logo" className="aboutus-page-logo-image" /></a>
                    <div class="aboutus-page-nav-wrapper">
                        <div class="aboutus-page-sl-nav">
                            <img
                                src="/icons/web_icon/language.png"
                                alt="language icon"
                                className="aboutus-page-language-icon"
                            />
                            <ul>
                                <li onClick={toggleDropdown} className="aboutus-page-dropdown-trigger">
                                English <i className="fa fa-angle-down" aria-hidden="true"></i>
                                <div className="aboutus-page-triangle"></div>
                                {isDropdownOpen && (
                                    <ul className="aboutus-page-dropdown-menu">
                                        <li>
                                            <span className="aboutus-page-active">English</span>
                                        </li>
                                        <a href = "/AboutUs/ES"><li><span>Español</span></li></a>
                                    </ul>
                                )}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="aboutus-page-nav-wrapper-400">
                        <div className="aboutus-page-sl-nav">
                            <img onClick={toggleDropdown}
                                src="/icons/web_icon/language.png"
                                alt="language icon"
                                className="aboutus-page-language-icon"
                            />
                            <ul>
                                <li onClick={toggleDropdown} className="aboutus-page-dropdown-trigger">
                                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                                    <div className="aboutus-page-triangle-400"></div>
                                    {isDropdownOpen && (
                                        <ul className="aboutus-page-dropdown-menu-400">
                                            <li><span className="aboutus-page-active">English</span></li>
                                            <a href = "/AboutUs/ES"><li><span>Español</span></li></a>
                                        </ul>
                                    )}
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/**1000px 이하에서만 보임 */}
                    <img 
                        src="/icons/web_icon/menu_45px.png" 
                        alt="menu Icon Image" 
                        className="aboutus-page-menu-image"
                        onClick={toggleMenu}
                    />
                </div>

                {/* 메뉴 컨테이너 */}
                <div className={`fs0_MenuContainerMobile ${menuVisible ? 'visible' : ''}`}>
                <button className="start-page-close-btn" onClick={toggleMenu}>X</button>
                    {/* 메뉴 내용 */}
                    <ul className="start-page-menu-list">
                    <li><a href="/AboutUs" className="start-page-menu-list-fs0_text">About Us</a></li>
                    <li><a href="/HowHealthy" className="start-page-menu-list-fs0_text">Event</a></li>
                    </ul>
                </div>

                <nav className="fs0_MenuContainer">
                    <a href="/AboutUs" className="fs0_text">About Us</a>
                    <a href="/HowHealthy" className="fs0_text">Event</a>
                    {/* <a href="#user-app" className="fs0_text">User App</a>
                    <a href="#doctor-guide" className="fs0_text">Doctor Guide</a> */}
                </nav>
            </div>
            <main className="aboutus-page-main">
                <section id="aboutus-page-main-here" className="hero">
                    <div className="hero-content">
                        <p className="aboutus-page-hero-content-p1">About Us</p>
                        <h2 className="aboutus-page-hero-h2">Everyone should have <br/> access to high-quality <br/>healthcare</h2>
                        <p>The new telemedicine service, <span className='mediknocks'>MediKnocks</span></p>
                    </div>
                </section>
                <section id="section05" class="demo">
                        <a href="#aboutus-page-what-is">
                            <span></span>Scroll
                        </a>
                </section>
            </main>
            <main className="aboutus-page-main-content">
                <section id = "aboutus-page-what-is" className="aboutus-page-what-is">
                    <p className="aboutus-page-what-is-content">
                        Welcome to Mediknocks! <br/><br/>
                        At Mediknocks, <span className='mediknocks'>we believe that everyone deserves access to high-quality healthcare at an affordable price,<br/>
                        regardless of insurance status.</span> Our innovative telemedicine platform connects you with certified healthcare<br/>
                        professionals through text-based consultations, ensuring that you receive expert care when and where you <br/>
                        need it—with or without the need for video chats.<br/>
                    </p>
                    <div className="what-is-content1">
                        <img className= "aboutus-page-img-2" src="/icons/web_icon/img_start2.png" alt="Doctor" />
                    </div>
                </section>
                
                <section className="aboutus-page-what-is2">
                    <p className="aboutus-page-what-is-content">
                        Whether through real-time text chats or at your convenience,  <br/>
                        <span className='mediknocks'>Mediknocks enables you to get the medical attention you need without the usual barriers of time, location, or high costs.</span><br/>
                        With our system, prescriptions are delivered quickly, so you can receive the treatment you need without delay.<br/>
                    </p>
                    <div className="what-is-content">
                    {/* <div className="what-is-content row_reverse"> */}
                        <img class="aboutus-page-img-3" src="/icons/web_icon/DanielKim.jpg" alt="Doctor" />
                        <div className="what-is-text text_right">
                            <div className="aboutus_div_fontUP1">
                                Daniel Kim
                            </div>
                            <div className="aboutus_div_fontUP2">
                                Chief Executive Officer
                            </div>
                        </div>
                    </div>
                </section>

                <section className="aboutus-page-what-is">
                    <p className="aboutus-page-what-is-content">
                        Our platform also supports communication in multiple languages. <br/>
                        <span className='mediknocks'>Thanks to our AI-powered automatic translation,</span> you can consult with doctors in the language you feel most comfortable with, <br/>
                        without any worries about communication barriers.<br/>
                    </p>
                    <div className="what-is-content">
                    {/* <div className="what-is-content row_reverse"> */}
                        <img class="aboutus-page-img-3" src="/icons/web_icon/PhillipKim.jpg" alt="Doctor" />
                        <div className="what-is-text text_right">
                            <div className="aboutus_div_fontUP1">
                                Phillip Kim
                            </div>
                            <div className="aboutus_div_fontUP2">
                                Chief Operating Officer
                            </div>
                        </div>
                    </div>
                </section>

                <section className="aboutus-page-what-is4">
                    <p className="aboutus-page-what-is-content">
                        Our mission is to make healthcare accessible, affordable, and efficient for everyone. <br/>
                        <span className='mediknocks'>No insurance? No problem. </span><br/>
                        With Mediknocks, quality care is available to all, anytime and anywhere.<br/><br/>

                        Experience affordable, quality healthcare with Mediknocks—on your terms, <br/>
                        through simple and efficient text-based consultations.<br/>

                        Thank you. <br/>
                    </p>

                    <img class="aboutus-page-img-4" src="/icons/web_icon/MediKnocksCEO.jpg" alt="Doctor" />
                </section>
            </main>

            <footer className="footer">
                <div className="footer-content">
                    <div className="footer-section">
                    <img src="/icons/web_icon/Logo.png" alt="MediKnocks Logo" className="footer-logo-image" />
                        <h3>Headquarters</h3>
                        <p>
                            MediKnocks, Inc. <br/>
                            5708 Sean Paul Lane <br/>
                            Georgetown Texas (78628) <br/><br/>
                            ⓒ 2022 MediKnocks, Inc.
                        </p>
                    </div>
                    <div className="footer-section">
                        <h3 className="footer-section-title">About Us</h3>
                        <a href="/AboutUs"><p>About Us</p></a>
                        <a href= "/HowHealthy"><p>Event</p></a>
                    </div>
                    <div className="footer-section">
                        <h3>Customer Support & Feedback</h3>
                        <p>Email: <a href="mailto:support@mediknocks.com" style={{color:"#01b6cd"}}>support@mediknocks.com</a></p>
                    </div>
                    <a href ="https://www.instagram.com/mediknocks/?fbclid=IwZXh0bgNhZW0CMTEAAR0IPClgflIEwm9gMrGz5EaOxbgdDMIFb2-TDzG8fOrX3qwRJ1cMXQXXrY4_aem_z93n3KVVwYD01rMLdd2dzQ"><img src="/icons/web_icon/Instagram_Glyph_Gradient.png" alt="instagram" className="aboutus-page-sns-button1"/></a>
                    <a href = "https://www.facebook.com/profile.php?id=61567539849561"><img src="/icons/web_icon/Facebook_Logo_Primary.png" alt="facebook" className="aboutus-page-sns-button1"/></a>
                </div>
            </footer>
        </div>
    );
}

export default AboutUs;