import React from 'react';
import '../css/PatientInfo.css';

const IP = process.env.REACT_APP_HOST;

function PatientInfo({ userCall, onViewHistory, onViewEventHistory, onOpenModal, onImpossibleTreatment, isChannelFrozen }) {
  if (!userCall) {
    return <div>Loading...</div>;
  }

  const {
    selectMedicalDep,
    writeSymptom,
    userName,
    age,
    userMedication,
    gender,
    userAdrugeffect,
    userMedicalhistory,
    iconImage,
    user_etc
  } = userCall;

  return (
    <div className="PatientInfo_div">
      <div className="PatientInfo_div-2">
        <div className="PatientInfo_combined-content">
          <div className="PatientInfo_box">
            <img
              loading="lazy"
              srcSet={`https://medi-knocks.com/icons/departments/${iconImage}_DeActive.png`}
              alt={`${selectMedicalDep} icon`}
              className="PatientInfo_img"
            />
            <div className="PatientInfo_div-4">{selectMedicalDep}</div>
          </div>
          
          <div className="PatientInfo_div-7">
            <div className="PatientInfo_div-8">
              <div className="PatientInfo_div-10">Patient Demographics</div>
            </div>
            <div className="PatientInfo_div-12">
              <div className="PatientInfo_div-13">
                <div className="PatientInfo_div-14">{userName}</div>
                <div className="PatientInfo_div-15">({age}, {gender})</div>
              </div>
            </div>
          </div>
          <ul>
            <li><div className="PatientInfo_div-5">Symptom</div></li>
          </ul>
          <textarea className="PatientInfo_div-6" readOnly value={writeSymptom}></textarea>
          <div className="medicanConinatner">
            <div className="PatientInfo_div-20">Medical History</div>
            <div className="PatientInfo_div-20-1">(Chronic illnesses)</div>
          </div>
          <ul>
            {userMedicalhistory && userMedicalhistory.length > 0 ? (
              userMedicalhistory.map((history, index) => (
                <li key={index}>{history}</li>
              ))
            ) : (
              <li>No medical history available</li>
            )}
          </ul>
          
          <div className='medicanConinatner'>
            <div className="PatientInfo_div-20">Medication&nbsp;</div>
            <div className='font_low'>(Current medications)</div>
          </div>
          <textarea className="PatientInfo_div-6-2" readOnly value={userMedication}></textarea>
          
          <div className='flex_div-19'>
            <div className="PatientInfo_div-20">Adverse drug effect&nbsp;</div>
            <div className='font_low'>(Medications with side effects)</div>
          </div>
          <textarea className="PatientInfo_div-6-3" readOnly value={userAdrugeffect}></textarea>
          
          <div className='flex_div-19'>
            <div className="PatientInfo_div-20">etc</div>
          </div>
          <textarea className="PatientInfo_div-6-4" readOnly value={user_etc}></textarea>         
          <hr className='PatientInfo_hr'/>
          <div className="PatientInfo_div-24">
              <button
                className="PatientInfo_div-22"
                onClick={onViewHistory}
                disabled={isChannelFrozen}
              >
                Past prescription record
              </button>
            <button
              className="PatientInfo_div-25"
              onClick={() => onOpenModal(userCall)}
              disabled={isChannelFrozen}
            >
              Diagnosis & prescribing
            </button>
            <button
              className="PatientInfo_div-26"
              onClick={onImpossibleTreatment}
              disabled={isChannelFrozen}
            >
              Complete the treatment
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PatientInfo;