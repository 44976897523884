import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from './Prescription/Modal';
import '../css/PaymentNotificationPopup.css';
import paymentIcon from './icons/payment-icon.png';

// const IP = 'localhost';
const IP = process.env.REACT_APP_HOST;
const PaymentNotificationPopup = ({ isOpen, onClose, url, drEmail, userEmail, sb }) => {
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    console.log("PaymentNotificationPopup opened with url:", url);
    if (isOpen && url) {
      axios.post('/api/getUserCallInfo', { channelUrl: url })
        .then(response => {
          if (response.data && response.data.chatPrgrs && response.data.chatPrgrs[0] && response.data.chatPrgrs[0].userCall && response.data.chatPrgrs[0].userCall[0]) {
            const medinoxPay = response.data.chatPrgrs[0].userCall[0].medical_pay;
            setAmount(medinoxPay);
          } else {
            console.error('Unexpected response structure:', response.data);
          }
        })
        .catch(error => {
          console.error('There was an error fetching the amount!', error);
        });
    }
  }, [isOpen, url]);

  const formattedAmount = typeof amount === 'number' ? amount.toFixed(2) : '0.00';

  const handleConfirm = () => {
    onClose();
    setTimeout(() => {
      window.location.reload();
    }, 500);  // 500ms 지연
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="payment-notification-popup">
        <h2 className="popup-title">Finish the treatment</h2>
        <img src={paymentIcon} alt="Payment Icon" className="payment-icon" />
        <p className="payment_amount">${amount}</p>
        <p className="payment_description actor-font">You've just treated a patient and earned this much!</p>
        <div className="button-container actor-font">
          
          <button className="paymeny_ok-button" onClick={handleConfirm}>OK</button>
        </div>
      </div>
    </Modal>
  );
};

export default PaymentNotificationPopup;