import React, { useState, useEffect, useRef } from 'react';
import '../css/Step1_CreateAccount.css';
import '../css/SignUp_Main.css';
import axios from 'axios';
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyB-3QdbBue-o9dJcGaLU66f1Yu-2aBgcaU",
  authDomain: "mediknocks-80fa0.firebaseapp.com",
  projectId: "mediknocks-80fa0",
  storageBucket: "mediknocks-80fa0.firebasestorage.app",
  messagingSenderId: "188232379712",
  appId: "1:188232379712:web:bc168fed707c5d10cedea5",
  measurementId: "G-75GKYHXYQY"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

function Step1_CreateAccount({ 
  formData, 
  setFormData, 
  errors, 
  setErrors, 
  isEmailVerified, 
  setIsEmailVerified, 
  emailMessage,    // 추가
  setEmailMessage, // 추가
  onNext 
}){
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [focusedInput, setFocusedInput] = useState(null);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isEmailCleared, setIsEmailCleared] = useState(false);
  const [verificationSent, setVerificationSent] = useState(false);
  const [verificationWaiting, setVerificationWaiting] = useState(false);
  const eventSourceRef = useRef(null);

  const handleNext = () => {
    let newErrors = {};

    // 이메일 검증
    if (!formData.email) {
      newErrors.email = 'Email Address is required';
      console.log("1");
    } else if (!validateEmail(formData.email)) {
      newErrors.email = 'Please Check your email';
      console.log("2");
    } else if (!isEmailVerified) {
      newErrors.email = 'Please verify your email address';
      console.log("3");
    }
    else {
      // 이메일이 검증된 경우에만 비밀번호 검증
      if (!formData.password) {
        newErrors.password = 'Password is required';
        console.log("4");
      } else if (!validatePassword(formData.password)) {
        newErrors.password = 'Password must be 8-15 characters and include uppercase, lowercase, number, and special character';
        console.log("5");
      }
      if (!formData.confirmPassword) {
        newErrors.confirmPassword = 'Confirm password is required';
        console.log("6");
      } else if (formData.password !== formData.confirmPassword) {
        newErrors.confirmPassword = 'Passwords not match';
        console.log("7");
      }
    }
    setErrors(newErrors);

    // 에러가 없고 모든 필드가 채워져 있을 때만 다음 단계로 진행
    if (Object.keys(newErrors).length === 0 && isEmailVerified && formData.email && formData.password && formData.confirmPassword) {
      onNext();
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));

    if (name === 'email') {
      setIsEmailCleared(false);
      resetEmailVerificationState();
      const isValid = validateEmail(value);
      setIsEmailValid(isValid);
      if (isValid) {
        setEmailMessage('Please use the button right to send a verification link to your email inbox');
      } else {
        setEmailMessage('');
      }
    }

    if (name === 'password' && formData.confirmPassword) {
      setErrors(prevErrors => ({
        ...prevErrors,
        confirmPassword: value !== formData.confirmPassword ? 'Passwords not match' : ''
      }));
    }

    // Real-time validation
    validateField(name, value);
  };

  const resetEmailVerificationState = () => {
    setIsEmailVerified(false);
    setIsEmailValid(false);
    setVerificationSent(false);
    setVerificationWaiting(false);
    setEmailMessage('');
    setIsEmailCleared(true);
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
      eventSourceRef.current = null;
    }
  };

  const validateField = (name, value) => {
    let error = '';

    switch (name) {
      case 'email':
        if (!value) {
          error = 'Email Address is required';
        } else if (!validateEmail(value)) {
          error = 'Please enter your email address in format: yourname@example.com';
        }
        break;
      case 'password':
        if (!value) {
          error = 'Password is required';
        } else if (!validatePassword(value)) {
          error = 'Password must be 8-15 characters and include uppercase, lowercase, number, and special character';
        }
        break;
      case 'confirmPassword':
        if (!value) {
          error = 'Confirm password is required';
        }
        if (value !== formData.password) {
          error = 'Passwords not match';
        }
        break;
      default:
        break;
    }

    setErrors(prevErrors => ({ ...prevErrors, [name]: error }));
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9]{2,}@[a-zA-Z0-9]{3,}\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePassword = (password) => {
    const re = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()\-_=+\\|[\]{};:\'",.<>/?]).{8,15}$/;
    return re.test(password);
  };

  const handleFocus = (field) => {
    setFocusedInput(field);
    setErrors(prevErrors => ({
      ...prevErrors,
      [field]: ''
    }));
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    setFocusedInput(null);
    validateField(name, value);
  };

  const handleTogglePassword = (field) => {
    if (field === 'password') {
      setShowPassword(!showPassword);
    } else if (field === 'confirmPassword') {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  const handleClearInput = (field) => {
    setFormData(prevData => ({
      ...prevData,
      [field]: ''
    }));
    if (field === 'email') {
      resetEmailVerificationState();
      setErrors(prevErrors => ({ ...prevErrors, email: '' }));
      setFocusedInput(null);  // 포커스 제거
    } else {
      setErrors(prevErrors => ({ ...prevErrors, [field]: '' }));
    }
  };

  useEffect(() => {
    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
    };
  }, []);

  const handleVerification = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/drVerificationStart', { email: formData.email });
      console.log("Email is: "+formData.email);
  
      if (response.status === 200) {
        setEmailMessage('Please check your email inbox and verify your email address');
        setErrors(prevErrors => ({ ...prevErrors, email: '' }));
        setVerificationSent(true);
        setVerificationWaiting(false);
  
        // 기존 SSE 연결이 있다면 닫기
        if (eventSourceRef.current) {
          eventSourceRef.current.close();
        }

        // 새로운 SSE 연결 시작
        const newEventSource = new EventSource(`/api/SignUpVerificationSEE?email=${encodeURIComponent(formData.email)}`, {
          withCredentials: true
        });
        console.log(newEventSource);
  
        newEventSource.onmessage = function(event) {
          const data = JSON.parse(event.data);
          console.log('Received:', data);
  
          if (data.verified === true) {
            setIsEmailVerified(true);
            setEmailMessage('Verified Your Email');
            setVerificationSent(false);
            setVerificationWaiting(false);
            newEventSource.close();
          } else if (data.verified === false) {
            setEmailMessage('Please check your email inbox and verify your email address');
            setVerificationSent(false);
            setVerificationWaiting(true);
          }
        };
  
        newEventSource.onerror = function(error) {
          console.error('SSE Error:', error);
          setEmailMessage('The email you entered is already registered ');
          setVerificationSent(false);
          setVerificationWaiting(false);
          newEventSource.close();
        };

        // 새 이벤트 소스를 ref에 저장
        eventSourceRef.current = newEventSource;
      }
    } catch (error) {
      console.error("Error during authentication:", error);
      setEmailMessage('The email you entered is already registered ');
      setErrors(prevErrors => ({ ...prevErrors, email: 'The email you entered is already registered ' }));
      setVerificationSent(false);
      setVerificationWaiting(false);
    }
  };


  return (
    <>
      <div className='Step1_title padding25p'>Create Account</div>
      <p className="Step1_subtitle">Create Account Please sign up to continue</p>
      <div className="step1_email_container padding25p">
      <div className={`Step1_input-group Step1_su_login_email 
            ${errors.email ? 'error' : ''} 
            ${focusedInput === 'email' ? 'focused' : ''} 
            ${verificationSent ? 'verification-sent' : ''}
            ${verificationWaiting ? 'verification-waiting' : ''}
            ${isEmailValid && formData.email ? 'valid-email' : ''}
            ${isEmailVerified ? 'verified-email' : ''}
          `}>
        <img src="/icons/web_icon/img_email.png" className="Step1_lg_img_step1" alt="Email icon" />
        <input
          type="email"
          name="email"
          placeholder="Email Address"
          value={formData.email}
          onChange={handleInputChange}
          onFocus={() => handleFocus('email')}
          onBlur={handleBlur}
          required
          className='Step1_su_input_email'
        />
        {formData.email && (
          <button type="button" className="Step1_btnClear" onClick={() => handleClearInput('email')}>
            ×
          </button>
        )}
      </div>
        <button
          className={`Step1_verification-button ${isEmailVerified ? 'verified' : ''}`}
          onClick={(e) => handleVerification(e)}
          disabled={!isEmailValid || isEmailVerified}
        >
          {isEmailVerified ? 'Verified Email' : 'Verification'}
        </button>
      </div>
      <div className="Step1_error-container">
        {errors.email ? (
          <div className="Step1_error-text_step1">{errors.email}</div>
        ) : emailMessage ? (
          <div className={
            emailMessage === 'Please use the button right to send a verification link to your email inbox' ? "Step1_password-guidance" :
            emailMessage === 'Verified Your Email' ? "Step1_verification-complete" :
            emailMessage === 'Please check your email inbox and verify your email address' ? "Step1_verification-sent" :
            "Step1_error-text_step1"
          }>
            {emailMessage}
          </div>
        ) : (
          <div className="Step1_password-guidance">
            Please use the button right to send a verification link to your email inbox
          </div>
        )}
      </div>

      <div className={`Step1_input-group Step1_su_login 
          ${errors.password ? 'error' : ''} 
          ${focusedInput === 'password' ? 'focused' : ''} 
          ${formData.password && validatePassword(formData.password) ? 'valid-password' : ''}
        `}>
        <img src="/icons/web_icon/img_password.png" className="Step1_lg_img_step1" alt="Password icon" />
        <input
          type={showPassword ? "text" : "password"}
          name="password"
          placeholder="Password"
          value={formData.password}
          onChange={handleInputChange}
          onFocus={() => handleFocus('password')}
          onBlur={handleBlur}
          required
          className='Step1_su_input_password'
        />
        <button type="button" className="Step1_toggle-password" onClick={() => handleTogglePassword('password')}>
          <img
            src={showPassword ? "/icons/web_icon/img_hiddenPassword.png" : "/icons/web_icon/img_showPassword.png"}
            alt={showPassword ? "Hide password" : "Show password"}
            className="Step1_password-toggle-icon"
          />
        </button>
      </div>
      <div className="Step1_error-container">
        {errors.password ? (
          <div className="Step1_error-text_step1">{errors.password}</div>
        ) : formData.password && validatePassword(formData.password) ? (
          <div className="Step1_correct-password">Correct password</div>
        ) : (
          <div className="Step1_password-guidance">
            Password must be 8-15 characters and include uppercase, lowercase, number, and special character
          </div>
        )}
      </div>

      <div className={`Step1_input-group Step1_su_login 
          ${errors.confirmPassword ? 'error' : ''} 
          ${focusedInput === 'confirmPassword' ? 'focused' : ''} 
          ${formData.confirmPassword && formData.confirmPassword === formData.password ? 'valid-password' : ''}
        `}>
        <img src="/icons/web_icon/img_password.png" className="Step1_lg_img_step1" alt="Password icon" />
        <input
          type={showConfirmPassword ? "text" : "password"}
          name="confirmPassword"
          placeholder="Confirm password"
          value={formData.confirmPassword}
          onChange={handleInputChange}
          onFocus={() => handleFocus('confirmPassword')}
          onBlur={handleBlur}
          required
          className='Step1_su_input_password'
        />
        <button type="button" className="Step1_toggle-password" onClick={() => handleTogglePassword('confirmPassword')}>
          <img
            src={showConfirmPassword ? "/icons/web_icon/img_hiddenPassword.png" : "/icons/web_icon/img_showPassword.png"}
            alt={showConfirmPassword ? "Hide password" : "Show password"}
            className="Step1_password-toggle-icon"
          />
        </button>
      </div>
      <div className="Step1_error-container">
        {errors.confirmPassword ? (
          <div className="Step1_error-text_step1">{errors.confirmPassword}</div>
        ) : formData.confirmPassword && formData.confirmPassword === formData.password ? (
          <div className="Step1_correct-password">Passwords match</div>
        ) : null}
      </div>
      <div className="button_center margin_20p">
        <button
          type="button"
          onClick={handleNext}
          className="Step1_next-button step1_margin10p"
        >
          Next
        </button>
      </div>
    </>
  );
}

export default Step1_CreateAccount;