import React from 'react';
import '../css/SignUp_Main.css';
import '../css/side_MedicalDepartment.css';

const departments = [
  { name: "Allergy and Immunology", icon: 'Allergy_and_Immunology' },
  { name: "Cardiology", icon: 'Cardiology' },
  { name: "Cardiothoracic Surgery", icon: 'Cardiothoracic_Surgery'},
  { name: "Dermatology", icon: 'Dermatology' },
  { name: "Emergency Medicine", icon: 'Emergency_Medicine' },
  { name: "Endocrinology", icon: 'Endocrinology' },
  { name: "Family Medicine", icon: 'Family_Medicine' },
  { name: "Gastroenterology", icon: 'Gastroenterology' },
  { name: "General Surgery", icon: 'General_Surgery' },
  { name: "Hematology", icon: 'Hematology' },
  { name: "Infectious Disease", icon: 'Infectious_Disease' },
  { name: "Internal Medicine", icon: 'Internal_Medicine' },
  { name: "Nephrology", icon: 'Nephrology' },
  { name: "Neurology", icon: 'Neurology' },
  { name: "Neurosurgery", icon: 'Neurosurgery' },
  { name: "Obstetrics and Gynecology", icon: 'Obstetrics_and_Gynecology' },
  { name: "Oncology", icon: 'Oncology' },
  { name: "Ophthalmology", icon: 'Ophthalmology' },
  { name: "Orthopedic Surgery", icon: 'Orthopedic_Surgery' },
  { name: "Orthopedics", icon: 'Orthopedic_Surgery' },
  { name: "Otolaryngology / ENT", icon: 'Otolaryngology_ENT'},
  { name: "Pediatrics", icon: 'Pediatrics' },
  { name: "Physical Medicine and Rehabilitation", icon: 'Physical_Medicine_Rehabilitation' },
  { name: "Plastic Surgery", icon: 'Plastic_Surgery' },
  { name: "Psychiatry", icon: 'Psychiatry' },
  { name: "Pulmonary Medicine", icon: 'Pulmonary_Medicine' },
  { name: "Rheumatology", icon: 'Rheumatology' },
  { name: "Urology", icon: 'Urology' }
];

function SideMedicalDepartment({ open, close, drInfo }) {
  if (!open) return null;

  const userDepartments = drInfo.medicalDepartment ? drInfo.medicalDepartment.split(',').map(dept => dept.trim()) : [];
  console.log(drInfo);

  const filteredDepartments = departments.filter(dept => 
    userDepartments.some(userDept => {
      const normalizedUserDept = userDept.toLowerCase().replace('&', 'and');
      const normalizedDeptName = dept.name.toLowerCase().replace('&', 'and');
      return normalizedUserDept === normalizedDeptName;
    })
  );

  return (
    <div className="sdmf_modal-overlay">
      <div className="sdmf_modal-content">
        <h2 className="sdmf_title">Medical department</h2>
        <p className="sdmf_subtitle">Check out your medical department</p>
        <div className="sdmf_department-container">
          <div className="sdmf_department-grid">
            {filteredDepartments.map((dept) => (
              <div key={dept.name} className="sdmf_department-button active">
                <img 
                  src={`./icons/departments/${dept.icon}_Active.png`}
                  alt={dept.name} 
                  className="sdmf_department-icon"
                />
                <span className="sdmf_department-name">{dept.name}</span>
              </div>
            ))}
          </div>
        </div>
        <div className='sdmf_button-center'>
          <button type="button" onClick={close} className="sdmf_cancel-button">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default SideMedicalDepartment;