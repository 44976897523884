import React from 'react';
import { SendbirdProvider } from '@sendbird/uikit-react/SendbirdProvider';
import { TypingIndicatorType } from '@sendbird/uikit-react';

const appId = process.env.REACT_APP_APP_ID; // SendBird 대시보드에서 가져온 Application ID


const SendbirdProviderWrapper = ({ userId, nickname, profileUrl, children }) => {
  return (
    <SendbirdProvider appId={appId} userId={userId} nickname={nickname} profileUrl={profileUrl}
    uikitOptions={{
      groupChannel: {
        // Below controls the toggling of the typing indicator in the group channel. The default is `true`.
        enableTypingIndicator: true,
        
        // Below turns on both bubble and text typing indicators. Default is `Text` only.
        typingIndicatorTypes: new Set([TypingIndicatorType.Bubble]),
      }
    }}>
      {children}
    </SendbirdProvider>
  );
};

export default SendbirdProviderWrapper;